import React, { Component } from "react";
import {Redirect, withRouter} from "react-router-dom";
import Cookies from 'universal-cookie';
import * as COOKIE_NAMES from '../constants/cookies';
import * as ROUTES from '../constants/routes';

const cookies = new Cookies();

class RedirectAfterLogin extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        let lastKnownPath = cookies.get(COOKIE_NAMES.PATH_COOKIE);
        let origin = lastKnownPath ? lastKnownPath : ROUTES.HOME;
        console.log("Redirecting to " + origin);
        return (<Redirect to={origin}/>)
    }
}

export default withRouter(RedirectAfterLogin);