import React from 'react';
import ReactDOM from 'react-dom';
import { Swipeable} from "react-swipeable";
import styles from "../../css/gallery.css";
import "react-alice-carousel/lib/alice-carousel.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import {FormattedMessage, injectIntl} from "react-intl";
import ImageOptionsBar from "./ImageOptionsBar";
import {withFirebase} from "./Firebase";
import MessageEditable from "../components/MessageEditable";
import * as ALERT_TYPES from "../constants/alertTypes";
import Alert from "../components/Alert";
import Confirmation from "../components/Confirmation";
import TranslatableMessage from "../components/TranslatableMessage";

var db = null;

class Gallery extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentIndex: 0,
            currentComments : [],
            userid : null,
            name : "",
            unsubscribe : null,
            commentToDelete : null,
            feedback : ""
        };
        let user = this.props.firebase.auth.currentUser;
        if (user != null) {
            this.state.userid = user.uid;
            this.state.name = user.displayName || "";
            console.log("Set user id for gallery to " + this.state.userid);
        }

    }

    componentDidMount() {
        this.state.unsubscribe = this.retrieveComments();
    }

    componentWillUnmount() {
        this.state.unsubscribe();
    }

    retrieveComments = () => {
        db = db || this.props.firebase.firestore;
        let self = this;
        return db.collection("messages-by-id")
            .orderBy("date", "asc").onSnapshot(function(querySnapshot) {
            self.setState({currentComments: []})
            querySnapshot.forEach(function(doc) {
                let message = {
                    messageid : doc.id,
                    userid : doc.data().uid,
                    otherid : doc.data().otherid,
                    name : doc.data().name,
                    date : doc.data().date,
                    content : doc.data().message,
                };
                console.log("Found some comments!");
                var currentComments = [...self.state.currentComments];
                currentComments.push(message);
                self.setState({currentComments: currentComments});
                console.log("Comments now has " + self.state.currentComments.length);
            });
        }, function(error) {
            console.log(error);
            self.updateFeedbackWithError(error);
        });
    };

    confirmDeleteComment = (commentId) => {
        let  comment = null;
        for(var i = 0; i < this.state.currentComments.length; i++) {
            if(commentId === this.state.currentComments[i].messageid) {
                comment = this.state.currentComments[i];
            }
        }
        if(comment) {
            this.setState({commentToDelete: comment});
        }
    }

    confirmationCancel = () => {
        this.setState({commentToDelete: null});
    }

    getDateForCommentToDelete = () => {
        if(this.state.commentToDelete) {
            return this.state.commentToDelete.date.toDate();
        }
        return new Date();
    }


    deleteConfirmed = () => {
        console.log("Deleting " + this.state.commentToDelete);
        db = db || this.props.firebase.firestore;
        let self = this;
        db.collection("messages-by-id").doc(self.state.commentToDelete.messageid).delete()
            .then(function() {
                self.setState({commentToDelete : null});
            }).catch(function(error) {
            self.updateFeedbackWithError(error);
            self.setState({commentToDelete : null});
        });
    };

    showAnonUserAlert = () => {
        const { formatMessage } = this.props.intl;
        this.updateFeedbackWithValidation(formatMessage({id: "like.anon.message"}));
    };

    updateFeedbackWithValidation = (info) => {
        this.setState({feedback : info});
    }

    updateFeedbackWithError = (error) => {
        this.setState({feedback : "error"});
    }

    clearFeedback = e => {
        e.preventDefault();
        this.setState({feedback : ""});
    }

    ensureActiveItemVisible() {
        var domNode = ReactDOM.findDOMNode(this).getElementsByClassName("thumb-" + this.state.currentIndex)[0];
        this.scrollElementIntoViewIfNeeded(domNode);

    }

    scrollElementIntoViewIfNeeded(node) {
        var panel = ReactDOM.findDOMNode(this).getElementsByClassName("thumbs")[0];
        console.log("Found a node " + node);
        console.log("Found a panel " + panel);
        if(this.state.currentIndex === 0) {
            panel.scrollLeft = 0;
            return;
        }
        if(this.state.currentIndex === this.props.galleryItems.length - 1) {
            panel.scrollLeft = panel.scrollWidth;
            return;
        }
        if (panel && node) {
            console.log("Info: " + node.offsetLeft + " " + panel.scrollLeft);
        }
        if (panel && node && (node.offsetLeft > panel.scrollLeft + (panel.offsetWidth - node.offsetWidth))) {
            panel.scrollLeft = node.offsetLeft;
        }
        if (panel && node && (node.offsetLeft < panel.scrollLeft) && (panel.scrollLeft > node.offsetLeft)) {
            panel.scrollLeft = panel.scrollLeft - node.offsetLeft;
        }

    }

    slideTo = (i) => this.setState({ currentIndex: i });



    slideNext = () => {
        if(this.state.currentIndex < this.props.galleryItems.length - 1) {
            this.setState({currentIndex: this.state.currentIndex + 1});
        } else {
            this.setState({currentIndex: 0});
        }
        if(!this.props.simpleGallery) {
            this.ensureActiveItemVisible(this.state.currentIndex);
        }
    }

    slidePrev = () => {
        if(this.state.currentIndex > 0) {
            this.setState({currentIndex: this.state.currentIndex - 1});
        } else {
            this.setState({currentIndex: this.props.galleryItems.length - 1});
        }
        if(!this.props.simpleGallery) {
            this.ensureActiveItemVisible(this.state.currentIndex);
        }
    }

    renderThumbs = () => {
        return <ul className="thumbs">{this.props.galleryItems.map((item, i) =>
            <li className={this.state.currentIndex === i ? "thumb image-in-carousel" : "thumb"} key={i} onClick={() => this.slideTo(i)}>
                <div className={"gallery-image thumb " + this.props.galleryItems[i] + " thumb-" + i}>
                    <div className={"image " + this.props.galleryItems[i]}/>
                </div>
            </li>)}
        </ul>;
    }

    renderPhoneCaptions = () => {
        return <ul className={this.props.galleryItems.length < 8 ? "phoneCaptions short" : "phoneCaptions long"}>
            {this.props.galleryItems.map((item, j) =>
                <li className={this.state.currentIndex === j ? "active" : "not-active"} key={j}><div className="caption phoneCaption">
                    <Swipeable onSwipedRight={(eventData) => this.slidePrev()} onSwipedLeft={(eventData) => this.slideNext()}>
                        {this.props.captionItems[j]}
                        {j < this.props.galleryItems.length - 1 ?
                            <p className="interactionHint">
                                <span className="clickHint"><FormattedMessage id={"gallery.hint.click"}/></span>
                                <span className="touchHint"><FormattedMessage id={"gallery.hint.touch"}/></span>
                            </p>
                            :
                            <p className="interactionHint">
                                <span className="clickHint"><FormattedMessage id={"gallery.hint.click.begin"}/></span>
                                <span className="touchHint"><FormattedMessage id={"gallery.hint.touch.begin"}/></span>
                            </p>
                        }
                        <div className="buttonContainer">
                            <button className="navButton backButton" onClick={() => this.slidePrev()}>&lt;</button>
                            <button className="navButton nextButton" onClick={() => this.slideNext()}>&gt;</button>
                        </div>
                    </Swipeable>
                </div>
                </li>)}
            </ul>;
    }


    renderGallery() {
        const { currentIndex, items } = this.state;

        window.resizeTo(window.outerWidth + 1, window.outerHeight);
        return (<ul className={this.props.simpleGallery ? "gallery-image-container simple" : this.props.fadeOutAnimation ? "gallery-image-container fade" : "gallery-image-container"}>
            {this.props.galleryItems.map((item, j) =>
             <li className={this.state.currentIndex === j ? "active" : this.state.currentIndex -1 === j? "prev" : this.state.currentIndex + 1 === j ? "next" : "not-active"} key={j}>
                <Swipeable className={"gallery-image main " + this.props.galleryItems[j]}
                           onSwipedRight={(eventData) => this.slidePrev()}
                           onSwipedLeft={(eventData) => this.slideNext()}>
                    {this.state.currentIndex === j ?
                    <ImageOptionsBar situStyleName="main-image"
                                     imageStyleName={"image " + this.props.galleryItems[j]}
                                     zoomPhoto={this.props.zoomPhoto}
                                     zoomOrigin="left"
                                     showLike={this.props.socialFunctions}
                                     showAnonUserAlertFunction={this.showAnonUserAlert}
                    /> : ""}

                </Swipeable>
                 {this.props.simpleGallery ? "" :
                    <Swipeable className="caption" onSwipedRight={(eventData) => this.slidePrev()} onSwipedLeft={(eventData) => this.slideNext()}>
                            {this.props.captionItems[j]}
                        {j < this.props.galleryItems.length - 1 ?
                             <p className="interactionHint">
                                <span className="clickHint"><FormattedMessage id={"gallery.hint.click"}/></span>
                                 <span className="touchHint"><FormattedMessage id={"gallery.hint.touch"}/></span>
                             </p>
                         :
                            <p className="interactionHint">
                                <span className="clickHint"><FormattedMessage id={"gallery.hint.click.begin"}/></span>
                                <span className="touchHint"><FormattedMessage id={"gallery.hint.touch.begin"}/></span>
                            </p>
                        }
                    </Swipeable>
                 }
             </li>)}

        </ul>);
    }

    render() {
        const { formatMessage } = this.props.intl;
        var anonUser = !this.state.name;
        var userId = this.state.userid;
        var name = this.state.name;
        let confirmationHeading = formatMessage({id : "confirmation.heading"});
        let confirmationExplanation = formatMessage({ id : "confirmation.comment.explain"});
        let confirmationOK = formatMessage({id : "confirmation.ok"});
        let confirmationCancel = formatMessage({id : "confirmation.cancel"});
        console.log("User id is " + userId);
        return (
            <div className={"galleryContainer " + this.props.lang}>
                <div className={this.props.simpleGallery ? "gallery simple slide" : this.props.fadeOutAnimation ? "gallery fade" : "gallery slide"}>
                    <div className="carousel">
                        <button className="navButton backButton" onClick={() => this.slidePrev()}>&lt;</button>
                            { this.renderGallery() }
                        <button className="navButton nextButton" onClick={() => this.slideNext()}>&gt;</button>
                    </div>
                    {this.props.simpleGallery ? "" :
                        <div className="thumbsAndPhoneCaptions">
                            {this.renderPhoneCaptions()}
                            {this.renderThumbs()}
                        </div>
                    }
                    {!this.props.withComments ? "" : <div className={"photo-comments"}>
                        <ul>
                            {this.state.currentComments.map((message, index) => (
                                message.otherid !== this.props.galleryItems[this.state.currentIndex] ? "" :
                                !anonUser && message.userid === userId ?
                                    <li key={index} className="message editable">
                                        <span className="commentName currentUserName">{name}</span>
                                        <MessageEditable userid={userId}
                                                         name={name}
                                                         messageid={message.messageid}
                                                         otherid={this.props.galleryItems[this.state.currentIndex]}
                                                         noLabels={true}
                                                         isReadOnlyName={true}
                                                         messagePlaceholder={"comment.placeholder"}
                                                         useIcons={true}
                                                         confirmDeleteFunction={this.confirmDeleteComment}/>
                                    </li>
                                    :
                                    <li key={index} className="message">

                                        <span className="commentName">{message.name}</span>
                                        <span className="content">
                                            <TranslatableMessage contentId={message.messageid} content={message.content} lang={this.props.lang}/>
                                        </span>
                                    </li>
                            ))
                            }
                        </ul>
                        {anonUser ? "" :
                            <div className={"editableComment"}>
                                <MessageEditable userid={userId}
                                                 name={name}
                                                 otherid={this.props.galleryItems[this.state.currentIndex]}
                                                 noLabels={true}
                                                 isReadOnlyName={true}
                                                 messagePlaceholder={"comment.placeholder"}
                                                 useIcons={true}/>
                            </div>
                        }

                    </div>}
                </div>
                <Confirmation show={this.state.commentToDelete != null}
                              onCancelFunction={this.confirmationCancel}
                              okFunction={this.deleteConfirmed}
                              heading={confirmationHeading}
                              message={confirmationExplanation}
                              okText={confirmationOK} cancelText={confirmationCancel}/>
                <Alert feedback={this.state.feedback} onCloseFunction={this.clearFeedback} type={this.state.feedback === "error" ? ALERT_TYPES.ERROR : ALERT_TYPES.VALIDATION}/>
            </div>
        );
    }
}

export default withFirebase(injectIntl(Gallery));