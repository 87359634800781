import styles from "../../css/videoplayer.css";
import React, { useEffect, useState }  from "react";
import VideoPlayer from "./VideoPlayer";
import * as FULL_SCREEN from "../constants/FullScreen";

// const fullscreenEnabled = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

function VideoPopupModal(props) {


    const [showModal, setShowModal] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);
    const [initVideo, setInitVideo] = useState(false);

    useEffect(() => {
        if(props.show && !initVideo) {
            setInitVideo(true);
        }
    }, [props.show])


    const closeModal = () => {
        setInitVideo(false);
        FULL_SCREEN.exitFullscreen();
        stopVideoAndHideModal();
        props.closePlayerFunction();
    };

    const fullScreen = (fsComp) => {
        if(props.show && initVideo && !playVideo) {
            if (!props.preventFullScreen && FULL_SCREEN.fullscreenEnabled && fsComp) {
                console.log("fsComp is " + fsComp);
                FULL_SCREEN.requestFullscreen(fsComp);
                showModalAndStartVideo();
            } else if (fsComp) {
                showModalAndStartVideo();
            }
        }
    };

    const showModalAndStartVideo = () => {
        setPlayVideo(true);
        setShowModal(true);
    };

    const stopVideoAndHideModal = () => {
        setShowModal(false);
        setPlayVideo(false);
    };


    return (
        <div className="fullScreenContainer" ref={function(fsContainer) {
            fullScreen(fsContainer)
            }}>
            <div className={showModal && props.show ? "video-player-visible modal-visible" :
                            "video-player-hidden modal-hidden"}>
                <div className="modal-background"/>
                <div className="videoPlayerContainer">
                    <div className="closeButton" onClick={closeModal}/>
                    <VideoPlayer videoId={props.show && showModal && playVideo ? props.videoCode : null}
                                 onEndFunction={closeModal} playVideo={props.show && showModal && playVideo}/>
                </div>
            </div>
        </div>

    )
}

export default VideoPopupModal;