import React, { Component } from "react";
import {
    NavLink
} from "react-router-dom";
import {FormattedMessage} from 'react-intl';
import styles from "../../css/main-menu.css";
import * as ROUTES from '../constants/routes';
import LanguageToggle from "./LanguageToggle";
import LogoutButton from "./LogoutButton";
import PageTitle from "./PageTitle";

class MainMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {isMenuToggled: false};
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState(state => ({
            isMenuToggled: !state.isMenuToggled
        }));
    }

    render() {
        return (
            <header className={this.state.isMenuToggled ? 'menu-shown' : 'menu-hidden'}>
                <nav className={this.state.isMenuToggled ? 'menu-shown' : 'menu-hidden'}>
                    <div className="header-bar">
                        <PageTitle/>
                        <div className="control-bar">
                            <LanguageToggle locale={this.props.locale} changeLanguageFunction={this.props.changeLanguageFunction}
                                            onChangeLanguage={this.props.onChangeLanguage}/>
                            {this.props.authUser ?
                                <div className={"protected-controls"}>
                                    <LogoutButton/>
                                    <div className={this.state.isMenuToggled ? "hamburger hamburger--collapse is-active" : "hamburger hamburger--collapse"} onClick={this.toggleMenu}>
                                        <div className="hamburger-box">
                                            <div className="hamburger-inner"></div>
                                        </div>
                                    </div>
                                </div>
                                : ""}
                        </div>
                    </div>
                    {this.props.authUser ?
                        <ul>
                            <li className="logout-button-line">
                                <LogoutButton/>
                            </li>
                            <li>
                                <NavLink to={ROUTES.HOME} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.home" description="Home menu option."/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={ROUTES.WEDDING} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.the.wedding" description="The wedding menu option."/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={ROUTES.PHOTOS} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.photos" description="Photos menu option."/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={ROUTES.OUR_STORY} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.our.story" description="Back story menu option."/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={ROUTES.CELEBRATIONS} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.celebrate" description="Celebration menu option."/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={ROUTES.MESSAGES} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.messages" description="Messages option."/>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={ROUTES.THANK_YOU} onClick={this.toggleMenu}>
                                    <FormattedMessage id="main.menu.thanks" description="Thanks menu option."/>
                                </NavLink>
                            </li>
                        </ul>
                        : ""
                    }
                </nav>
            </header>
        );
    }
}

export default MainMenu;