import React from "react";
import styles from "../../css/modal-messages.css";


function Confirmation(props) {

    return (
        <div className={props.show ? "message-visible modal-visible" : "message-hidden modal-hidden"}>
            <div className="modal-background"></div>
            <div className="message confirmation">
                <h1>{props.heading}</h1>
                <p>{props.message}</p>
                <div className="buttons">
                    <button onClick={props.onCancelFunction}>{props.cancelText}</button>
                    <button onClick={props.okFunction}>{props.okText}</button>
                </div>
            </div>
        </div>
    );
}

export default Confirmation;