import 'react-app-polyfill/ie11';
import React from "react";
import ReactDOM from "react-dom";
import Main from "./Main";
import Firebase, { FirebaseContext } from './js/components/Firebase';
import "./index.css";

ReactDOM.render(
    <FirebaseContext.Provider value={new Firebase()}>
        <Main/>
    </FirebaseContext.Provider>,
    document.getElementById('root'),
);