import React from "react";
import {FormattedDate, FormattedMessage, FormattedTime} from "react-intl";
import MessageDate from "./MessageDate";

function Message(props) {
    let message = props.message;
  return (
    <div className="message">
      <MessageDate date={message.date.toDate()}/>
      <div className="content">
          <label><FormattedMessage id="message.form.content"/></label>
          <div className="value">
              <span className="staticValue">{message.content}</span>
          </div>
      </div>
      <div className="name">
          <label><FormattedMessage id="message.form.name"/></label>
          <div className="value">
              <span className="staticValue">{message.name}</span>
          </div>
      </div>
    </div>
  );
}

export default Message;
