import React from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import styles from "../../css/modal-messages.css";
import * as ALERT_TYPES from "../constants/alertTypes"

function Alert(props) {
    const { formatMessage } = props.intl;
    let heading, message, css = "";
    console.log("Feedback is now " + props.feedback);
    let showAlert = props.feedback && props.feedback !== "";
    if(showAlert) {
        heading = formatMessage({id: "alert.heading." + props.type});
        message = formatMessage({id: props.type === ALERT_TYPES.ERROR ? "alert.message.error" : props.feedback});
        css = props.type;
    }
    let okMessage = formatMessage({id: "alert.ok"});
    return (
        <div className={showAlert ? "message-visible modal-visible" : "message-hidden modal-hidden"}>
            <div className="modal-background"></div>
            <div className={"message alert " + css}>
                <h1>{heading}</h1>
                <p>{message}</p>
                <button onClick={props.onCloseFunction}>{okMessage}</button>
            </div>
        </div>
    );
}

export default injectIntl(Alert);