import React, { Component } from "react";
import {FormattedMessage} from "react-intl";
import styles from "../../css/story.css";
import ImageOptionsBar from "../components/ImageOptionsBar";
import IFrameBox from "../components/IFrameBox";
import VideoPopupModal from "../components/VideoPopupModal";

class OurStory extends Component {

    constructor() {
        super();
        this.state = {
          switchedOn : false,
          showModal : false
        };
    }

    toggleSwitch = () => {
        this.setState({switchedOn : !this.state.switchedOn});
    }

    openModal = () => {
        this.setState({showModal : true});
    }

    closeModal = () => {
        this.setState({showModal : false});
    }

    render() {
        return (
            <div className = {this.state.switchedOn ? "contentModule light-on" : "contentModule light-off" }>
                <div className="mainBackgroundContainer">
                    <ImageOptionsBar situStyleName={this.state.switchedOn ? "innerMainBackground with-zoom" : "innerMainBackground zoom-disabled"} imageStyleName={"storyBackground-on"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                    <ImageOptionsBar situStyleName={this.state.switchedOn ? "innerMainBackground zoom-disabled" : "innerMainBackground with-zoom"} imageStyleName={"storyBackground-off"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                </div>
                <a onClick={this.toggleSwitch} className={"story-link light-link"}>
                    <span className={"hint " + this.props.locale}>{this.state.switchedOn ? <FormattedMessage id="story.light.switch.on.hint"/> : <FormattedMessage id="story.light.switch.off.hint"/>}</span>
                </a>
                <p><FormattedMessage id="story.intro"/></p>
                <div className="buttonWrapper"><a className="story-link play-game-link button" onClick={this.openModal}><FormattedMessage id="story.what.happened.next.link"/></a></div>
                <div className="backgroundElements graffiti"/>
                <VideoPopupModal show={this.state.showModal} closePlayerFunction={this.closeModal}
                                 videoCode={this.props.locale === "en" ? "iX4VYyeFfJY" : "ZifT3Mv79vc"} preventFullScreen={true}/>
            </div>
        );
    }
}

export default OurStory;