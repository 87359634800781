import React from 'react';
import YouTube from '@u-wave/react-youtube';
import VideoPopupModal from "./VideoPopupModal";


function VideoPlayer(props) {

    return (
        <YouTube
            video={props.videoId}
            autoplay={props.playVideo}
            modestBranding={true}
            showRelatedVideos={false}
            showInfo={false}
            annotations={false}
            paused={props.stopVideo}
            onEnd={props.onEndFunction}
        />

    )
}


export default VideoPlayer;