import React, { Component } from "react";
import HistoryListener from "./HistoryListener";
import {FormattedMessage} from "react-intl";
import * as PAGE_NAMES from "../constants/routes"
import {withFirebase} from "./Firebase";


class PageTitle extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pageTitle : PAGE_NAMES.PAGE_NAME_EMPTY
        }
    }

    onPageChanged = (path) => {
        console.log("Current path in page name: " + path);
        let pageTitle = "";
        if(path != "") {
            console.log("Matching path");
            switch (path) {
                case PAGE_NAMES.HOME: pageTitle = PAGE_NAMES.PAGE_NAME_HOME; break;
                case PAGE_NAMES.OUR_STORY: pageTitle = PAGE_NAMES.PAGE_NAME_OUR_STORY; break;
                case PAGE_NAMES.WEDDING: pageTitle = PAGE_NAMES.PAGE_NAME_WEDDING; break;
                case PAGE_NAMES.PHOTOS: pageTitle = PAGE_NAMES.PAGE_NAME_PHOTOS; break;
                case PAGE_NAMES.CELEBRATIONS: pageTitle = PAGE_NAMES.PAGE_NAME_CELEBRATIONS; break;
                case PAGE_NAMES.MESSAGES: pageTitle = PAGE_NAMES.PAGE_NAME_MESSAGES; break;
                case PAGE_NAMES.THANK_YOU: pageTitle = PAGE_NAMES.PAGE_NAME_THANK_YOU; break;
                case PAGE_NAMES.SIGN_IN: pageTitle = PAGE_NAMES.PAGE_NAME_SIGN_IN; break;
                default: pageTitle = PAGE_NAMES.PAGE_NAME_EMPTY;
            }
        } else {
            pageTitle = PAGE_NAMES.PAGE_NAME_EMPTY;
        }
        this.setState({pageTitle : pageTitle});
    }


    render() {
        var user = this.props.firebase.auth.currentUser;
        var isSignedOut = !user;
        return (
            isSignedOut ? "" :
            <h1 className="mainPageTitle">
                <HistoryListener currentPageChanged={this.onPageChanged}/>
                <FormattedMessage id={this.state.pageTitle}/>
            </h1>
        );
    }
}

export default withFirebase(PageTitle);