import React, { Component } from "react";
import gearRings from "../../images/fancygears-move.gif";
import {FormattedMessage} from 'react-intl';
import styles from "../../css/footer.css";
import CountDownClock from "./CountDownClock";

class Footer extends Component {

    render() {
        return (
            <footer className={this.props.authUser ? "logged-in" : "not-logged-in"}>
                {this.props.authUser ?
                    <div className="elopementDate">
                        <p className="gearsAnnouncementWrapper">
                            <span><FormattedMessage id="main.we.eloped" description="We eloped description."/></span>
                            <img className="gearRings" src={gearRings}/>
                            <span><FormattedMessage id="main.footer.date" description="Footer date description."/></span>
                        </p>
                        <p className="parentheses">(<CountDownClock/>)</p>
                    </div> : ""}
                <p className="notices">
                    <span className="copyrightNotice">&copy; Betsy & <FormattedMessage id="Ivan"/> 2019.</span>
                    <span className="poweredByNotice">
                        <FormattedMessage id="main.footer.info" description="Footer info description."/>
                        <img className="reactLogo" src="https://cdn-images-1.medium.com/fit/c/40/40/1*bN7QIcplxj6fSnmhBbqsbA.jpeg"/>
                    </span>
                </p>
            </footer>
        );
    }
}

export default Footer;