import React, { Component } from "react";
import styles from "../../css/home.css";
import {FormattedMessage} from 'react-intl';
import VideoPopupModal from "../components/VideoPopupModal";
import Cookies from "universal-cookie";
import * as COOKIE_NAMES from "../constants/cookies";
import * as FULL_SCREEN from "../constants/FullScreen";
import ImageOptionsBar from "../components/ImageOptionsBar";

const cookies = new Cookies();

class Home extends Component {

    constructor(props) {
        super(props);
        this.state = {loadVideo : false, checkFullScreen : false};
    }

    closeVideoModal = (requestExit) => {
        this.setState({loadVideo : false});
        this.props.welcomeVideoPlayedFunction();
        cookies.set(COOKIE_NAMES.LANDING_VIDEO_ALREADY_PLAYED_COOKIE, true, { path: '/', expires: new Date(Date.now()+30000000000) });
    }

    cookieVideoNotPlayed = () => {
        return cookies.get(COOKIE_NAMES.LANDING_VIDEO_ALREADY_PLAYED_COOKIE);
    }

    playLandingVideo = () => {
        this.setState({loadVideo : true});
    }

    render() {
        return (
            <div className="contentModule">
                <VideoPopupModal show={this.state.loadVideo} closePlayerFunction={this.closeVideoModal}
                                 videoCode={this.props.locale === "en" ? "TDnxcBrO4vI" : "XmAh3e-_MwY"} preventFullScreen={false}/>
                <h2 className="banner">Betsy & <FormattedMessage id="Ivan"/> - 20.05.19</h2>
                <ImageOptionsBar situStyleName={"mainBackground with-zoom"} imageStyleName={"homeBackground"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                <a className="link-play-video" onClick={this.playLandingVideo}>
                    <span className="hint first pointer"><FormattedMessage id="home.play.video.pointer"/></span>
                    <span className="hint first touch"><FormattedMessage id="home.play.video.touch"/></span>
                    <span className="hint after"><FormattedMessage id="home.play.video.again"/></span>
                </a>
            </div>
        );
    }
}

export default Home;