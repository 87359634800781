import React, { Component } from "react";
import {FormattedMessage} from "react-intl";
import styles from "../../css/photo.css";
import ImageOptionsBar from "../components/ImageOptionsBar";
import Gallery from "../components/Gallery"
import Caveat from "../components/Caveat"
import BackToTheTop from "../components/BackToTheTop";

class Photos extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        return (
            <div className="contentModule">
                <ImageOptionsBar situStyleName={"mainBackground with-zoom"} imageStyleName={"photoBackground"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                <section className="subSection photo-summary">
                    <h2><FormattedMessage id="photos.summary.heading"/></h2>
                    <p><FormattedMessage id="photos.summary.1"/></p>
                    <p><FormattedMessage id="photos.summary.2"/></p>
                    <p><FormattedMessage id="photos.summary.3"/></p>
                </section>
                <Caveat suffix="photo"/>
                <section className="subSection photo-gallery with-gallery with-comments">
                    <h2><FormattedMessage id="photos.summary.heading.2"/></h2>
                    <p className="galleryInstructions hoverInstruction">
                        <span className={"showZoomButton"}><FormattedMessage id="photos.summary.4"/></span>
                        <span className={"showHeartButton"}><FormattedMessage id="photos.summary.5"/></span>
                    </p>
                    <Gallery lang={this.props.locale}
                             zoomPhoto={this.props.zoomPhoto}
                             simpleGallery={true}
                             socialFunctions={true}
                             withComments={true}
                             galleryItems={[

                                 "church-1","church-2","church-3","church-4","church-5",
                                 "church-6","church-7","church-8","church-9","church-10",
                                 "church-11","church-12","church-13","church-14","church-15",
                                 "church-16","church-17","church-18","church-19", "church-20",
                                 "church-21","church-22",

                                 "location-1-1","location-1-2","location-1-3","location-1-4","location-1-5",
                                 "location-1-6","location-1-7","location-1-8","location-1-9","location-1-10",
                                 "location-1-11","location-1-12","location-1-13","location-1-14",

                                 "location-2-1","location-2-2","location-2-3","location-2-4","location-2-5",
                                 "location-2-6","location-2-7","location-2-8","location-2-9","location-2-10",
                                 "location-2-11","location-2-12","location-2-13","location-2-14","location-2-15",
                                 "location-2-16","location-2-17","location-2-18",

                                 "location-3-1","location-3-2","location-3-3","location-3-4","location-3-5",
                                 "location-3-6","location-3-7","location-3-8","location-3-9","location-3-10",
                                 "location-3-11","location-3-12","location-3-13","location-3-14","location-3-15",
                                 "location-3-16","location-3-17","location-3-18","location-3-19","location-3-20",
                                 "location-3-21","location-3-22","location-3-23","location-3-24","location-3-25",

                                 "first-title",

                                 "second-title",

                                 "candid-1","candid-2","candid-3","candid-4","candid-5",
                                 "candid-6","candid-7","candid-8","candid-9","candid-10",

                                 "third-title"

                             ]}></Gallery>
                </section>
                <BackToTheTop/>
                <div className="backgroundElements photo"/>
            </div>
        );
    }
}

export default Photos;