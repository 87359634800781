import React, { Component } from "react";
import LoginForm from "../components/LoginForm";
import LoadingIndicator from "../components/LoadingIndicator";
import RedirectAfterLogin from "./RedirectAfterLogin";


class Login extends Component {

    constructor(props) {
        super(props);
    }

    render() {
        if (this.props.loaded) {
            if (!this.props.authUser) {
                console.log("path:" + this.props.path);
                return (
                    <LoginForm locale={this.props.locale}/>
                );
            } else {
                return (
                    <RedirectAfterLogin/>
                );
            }
        } else {
            return (
                <LoadingIndicator/>
            )
        }
    }
}

export default Login;