import React from 'react';
import {FormattedMessage} from "react-intl";
import {withFirebase} from "../components/Firebase";
import * as ROUTES from "../constants/routes";
import {NavLink} from "react-router-dom";

function NotFound(props) {
    var user = props.firebase.auth.currentUser;
    var isSignedOut = !user;
    return (
        <div className="contentModule comp404">
            <div className="mainBackground background404"></div>
            <section className="subSection photo-summary">
                <h2><FormattedMessage id="404.info.heading"/></h2>
                <p><FormattedMessage id="404.info.1"/></p>
                {!isSignedOut ?
                <p><FormattedMessage id="404.info.2"/></p>
                    :
                    <div>
                        <p><FormattedMessage id="404.info.2.login"/></p>
                        <NavLink
                            to={ROUTES.SIGN_IN}
                        ><FormattedMessage id="404.info.2.link"/></NavLink>
                    </div>
                }
            </section>
        </div>
    )
}

export default withFirebase(NotFound);