import React from "react";
import {FormattedMessage} from "react-intl";
import { withFirebase } from './Firebase';
import styles from "../../css/logout-button.css";


function LogoutButton(props) {
    var user = props.firebase.auth.currentUser;
    var isSignedOut = !user;
    var isNamedUser = !isSignedOut && (user.displayName && user.email);

    const logout = () => {
        props.firebase.doSignOut();
        window.location.reload();
    }


    return (
        <div className={isSignedOut ? "hide-me hidden-logout-button" : isNamedUser ? "logout-button" : "logout-button anon"}
             onClick={logout}>
            {isSignedOut ?
                "" :
                isNamedUser ?
                <FormattedMessage id="logout.button"/> :
                <FormattedMessage id="logout.button.anon.user"/>
            }
        </div>
    );
}

export default withFirebase(LogoutButton);