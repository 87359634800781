import React from "react";
import styles from "../../css/image-options.css";
import LikeButton from "./LikeButton";
import LikeList from "./LikeList";

class ImageOptionsBar extends React.Component {

    constructor(props) {
        super(props);
    }

    showZoomModal = () => {
        this.props.zoomPhoto(this.props.imageStyleName, this.props.zoomOrigin);
    }

    render() {
        return (
            <div className={"image-with-options"}>
                <div className={"photo-box-container " + this.props.situStyleName + " " + this.props.imageStyleName}>
                    {this.props.showLike ?
                        <div className={"image-options-bar withLikeButton"}>
                            <div className="magnifyButton imageOptionButton" onClick={this.showZoomModal}/>
                            <LikeButton likeId={this.props.imageStyleName} className={"imageOptionButton"} showAnonUserAlertFunction={this.props.showAnonUserAlertFunction}/>
                        </div>
                        :
                        <div className="magnifyButton imageOptionButton" onClick={this.showZoomModal}/>}

                </div>
                {this.props.showLike ? <LikeList likeId={this.props.imageStyleName}/> : ""}
            </div>

        )
    }
}

export default ImageOptionsBar;