import React, { Component } from "react";
import {FormattedMessage} from "react-intl";
import {FirebaseAuth} from 'react-firebaseui';
import { withFirebase } from '../components/Firebase';

import styles from "../../css/loginform.css";
import firebase from "firebase";
import Cookies from "universal-cookie";
import * as COOKIE_NAMES from "../constants/cookies";
import LoadingIndicator from "./LoadingIndicator";

const cookies = new Cookies();
const loggedInBefore = cookies.get(COOKIE_NAMES.LOGGED_IN_BEFORE);

class LoginForm extends Component {

    constructor(props) {
        super(props);
        this.state = {loading: false};
        if(!loggedInBefore) {
            cookies.set(COOKIE_NAMES.LOGGED_IN_BEFORE, true)
        }
    }

    loginAnonymously = () => {
        this.setState({loading : true});
    }

    componentDidUpdate() {
        if(this.state.loading) {
            this.props.firebase.doLoginAnoynmously();
        }
    }

    uiConfig = {
        signInFlow: 'redirect',
        signInSuccessUrl: '/',
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            firebase.auth.FacebookAuthProvider.PROVIDER_ID
        ]
    };

    render() {
        return (
            <div className={"loginForm locale-" + this.props.locale}>
                {loggedInBefore ?
                    ""
                    :
                    <div>
                        <h2><FormattedMessage id={"login.form.welcome"}/></h2>
                        <p><FormattedMessage id={"login.intro.screen"}/></p>
                        <p><FormattedMessage id={"login.intro.instruction"}/></p>
                    </div>
                }
                 <FirebaseAuth uiConfig={this.uiConfig} firebaseAuth={this.props.firebase.auth}/>
                 <button className={"guest-login-button loading-" + this.state.loading} onClick={this.loginAnonymously}>
                     <FormattedMessage id="login.skip.button"/>
                     <LoadingIndicator/>
                 </button>
                 <p className="smallPrint">
                     <span className="intro"><FormattedMessage id={"login.cookie.intro"}/>
                     {loggedInBefore ? <FormattedMessage id={"login.cookie.intro.extra"}/>  : ": "}</span>
                     <FormattedMessage id={"login.cookie.warning"}/>
                 </p>
            </div>
        );
    }

}

export default withFirebase(LoginForm);