import React, {Component} from "react";
import { withRouter } from 'react-router';


    class HistoryListener extends Component {

        constructor(props) {
            super(props);
            props.history.listen( location =>  {
                props.currentPageChanged(location.pathname)
            });
        }

        render() {
            return "";
        }

    }

export default withRouter(HistoryListener);