import React from 'react';
import {withFirebase} from "./Firebase";
import LogoutButton from "./LogoutButton";
import {FormattedMessage} from "react-intl";
import styles from "../../css/caveat.css";


function Caveat(props) {
    var user = props.firebase.auth.currentUser;
    var isSignedOut = !user;
    var isNamedUser = !isSignedOut && (user.displayName && user.email);

    return (
        isNamedUser ?
                "" :
            <section className="caveat subSection">
                <h3><FormattedMessage id="anon.user.caveat.heading"/></h3>
                <p className="suggestion"><FormattedMessage id={"anon.user.caveat.1." + props.suffix}/></p>
                <p className="suggestion-hint"><FormattedMessage id={"anon.user.caveat.2"}/></p>
                <LogoutButton/>
            </section>
    )
}

export default withFirebase(Caveat);