import React from 'react';
import {Redirect, withRouter} from "react-router-dom";
import * as ROUTES from "../constants/routes";

function PrivateRoute(props) {
    if (props.authUser) {
        return props.children;
    }
    return (
        <Redirect
            to={ROUTES.SIGN_IN}
        />
    );
}

export default withRouter(PrivateRoute);