import React from "react";
import styles from "../../css/image-options.css";

class PhotoZoom extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.showPhotoZoom ? "photo-zoom-box zoomed modal-visible " + this.props.zoomOrigin :
                "photo-zoom-box not-zoomed modal-hidden " + this.props.zoomOrigin }>
                <div className={"photo-container"}>
                    <div className="closeButton" onClick={this.props.hidePhotoZoomFunction}/>
                    <div className={"image " + this.props.photoStyleName}/>
                </div>
            </div>

        )
    }
}

export default PhotoZoom;