import React, {Component} from "react";
import {FormattedMessage} from "react-intl";

class BackToTheTop extends Component {

    backToTheTop = () => {
        window.scrollTo(0, 0)
    }

    render() {
        return (<a className="backToTheTopLink" onClick={this.backToTheTop}><FormattedMessage id="back.to.the.top"/></a>);
    }
}

export default BackToTheTop;