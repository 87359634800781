import React from 'react';
import {withFirebase} from "./Firebase";
import {FormattedMessage, injectIntl} from 'react-intl';
import styles from "../../css/notification-form.css";
import EditableField from "./EditableField";
import LogoutButton from "./LogoutButton";
import Alert from "./Alert";
import * as ALERT_TYPES from "../constants/alertTypes";

var db = null;

class NotificationAddressForm extends React.Component {


    constructor(props) {
        super();
        this.props = props;
        this.state = {
            notificationId: null,
            userid: "",
            email: "",
            fullname: "",
            feedback: "",
            feedbackType: "",
            justUpdated: false,
            notificationExists: false,
            notificationType: this.props.collection,
            language: this.props.locale,
        };
        let user = this.props.firebase.auth.currentUser;
        if (user != null) {
            this.state.fullname = user.displayName || "";
            this.state.email = user.email || "";
            if(user.displayName && user.email) {
                this.state.notificationId = user.uid + this.props.collection;
            } else {
                this.state.notificationId = null;
            }
        }

    }

    componentDidMount() {
        if(this.state.notificationId) {
            this.retrieveNotificationAddress();
        }
    }

    retrieveNotificationAddress = () => {
        db = db || this.props.firebase.firestore;
        let docRef = db.collection("notifications").doc(this.state.notificationId);
        var self = this;
        docRef.get().then(function(doc) {
            let docExists = doc.exists;
            if(docExists) {
                self.setState({notificationExists: doc.exists,
                    fullname: doc.data().name,
                    email: doc.data().email,
                    language: doc.data().language});
            }
        }).catch(function(error) {
            self.updateFeedbackWithError(error)
        });

    };

    deleteNotificationAddress = e => {
        var self = this;
        db.collection("notifications").doc(this.state.notificationId).delete().then(function() {
            self.setState({notificationExists : false});
        }).catch(function(error) {
            self.updateFeedbackWithError(error)
        });
    };

    validateNotificationAddress = e => {
        e.preventDefault();
        if(this.state.fullname.trim().length === 0) {
            this.updateFeedbackWithValidation("validation.message.no.name.for.notification");
        } else if (this.state.email.trim().length === 0) {
            this.updateFeedbackWithValidation("validation.message.no.email.for.notification");
        } else {
            if (!db) {
                db = this.props.firebase.firestore;
            }
            let self = this;
            let notificationId;
            if(this.state.notificationId) {
                notificationId = this.state.notificationId;
                this.addNotificationAddress(self, notificationId);
            } else {
                var now = new Date();
                notificationId = "anon" + this.state.fullname + now.getTime();
                this.addNotificationAddressIfItDoesNotExist(self, notificationId);
            }
            console.log("Adding notification for" + notificationId);

        }
    };

    addNotificationAddressIfItDoesNotExist = (self, notificationId) => {
        db.collection("notifications").where("email", "==" , this.state.email).where("type","==",this.state.notificationType)
            .get()
            .then(function(querySnapshot) {
                if(!querySnapshot.empty) {
                    self.updateFeedbackWithValidation("notification.address.form.anon.notification.ready.exists");
                } else {
                    self.addNotificationAddress(self, notificationId);
                }
            })
            .catch(function(error) {
                self.updateFeedbackWithError(error)
            });
    }

    addNotificationAddress = (self, notificationId) => {
        db.collection("notifications").doc(notificationId).set({
            userid: this.state.userid,
            type: this.state.notificationType,
            name: this.state.fullname,
            email: this.state.email,
            language: this.state.language
        })
        .then(function () {
            self.setState({justUpdated: true});
            if(self.state.notificationId) {
                self.retrieveNotificationAddress();
            } else {
                self.updateFeedbackWithInfo("notification.setting.saved.anon");
                self.setState({fullname: "", email: ""});
            }
        })
        .catch(function (error) {
            self.updateFeedbackWithError(error)
        });
    }

    updateFeedbackWithError = (error) => {
        this.setState({feedback : "error", feedbackType : ALERT_TYPES.ERROR});
    }

    updateFeedbackWithValidation = (info) => {
        this.setState({feedback : info, feedbackType : ALERT_TYPES.VALIDATION});
    }

    updateFeedbackWithInfo = (info) => {
        this.setState({feedback : info, feedbackType : ALERT_TYPES.INFO});
    }

    clearFeedback = e => {
        e.preventDefault();
        this.setState({feedback : ""});
    }

    updateInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    onEdit = () => {
    }

    onCancelNameEdit = () => {
        let user = this.props.firebase.auth.currentUser;
        if (user != null) {
            this.setState({fullname : user.displayName || ""});
        }
    }

    onCancelAddressEdit = () => {
        let user = this.props.firebase.auth.currentUser;
        if (user != null) {
            this.setState({email : user.email || ""});
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let translatedDescription = formatMessage({ id: this.props.updatedescription});
        let translatedWhen = formatMessage({ id: this.state.justUpdated ? "notification.address.form.notification.requested.when.just" :
                "notification.address.form.notification.requested.when.already"});
        let descriptionValue = {updatedescription: translatedDescription, when: translatedWhen};
        let language = formatMessage({id: "language.toggle." + this.state.language});
        let descriptionValues2 = {name: this.state.fullname, address: this.state.email, language: language};
        return (
            <div className="notificationContainer">
                {this.state.notificationExists ?
                    <div>
                        <p><FormattedMessage id="notification.address.form.notification.already.requested"
                                          values={descriptionValue}/></p>
                        <p><FormattedMessage id="notification.address.form.notification.already.requested.2"
                                          values={descriptionValues2}/></p>
                        <a onClick={this.deleteNotificationAddress}><FormattedMessage id="notification.address.form.notification.already.undo.link"/></a>
                    </div>
                    :
                    <form onSubmit={this.validateNotificationAddress}>
                        <h3><FormattedMessage id="notification.address.form.heading"/></h3>
                        {this.state.notificationId ?
                            <p><FormattedMessage id="notification.address.form.instruction" values={descriptionValue}/></p>
                            :
                            <p><FormattedMessage id="notification.address.form.anon.user.instruction" values={descriptionValue}/></p>
                        }
                        <fieldset>
                            <EditableField
                                label={"notification.address.form.name"}
                                name="fullname"
                                placeholder={"notification.address.form.name.placeholder"}
                                changefunction={this.updateInput}
                                oneditfunction={this.onEdit}
                                oncancelfunction={this.onCancelNameEdit}
                                value={this.state.fullname}
                                inputtype="text"
                                notoggle={!this.state.notificationId}
                                initialiseinedit={true}
                            />
                            <EditableField
                                label={"notification.address.form.address"}
                                name="email"
                                placeholder={"notification.address.form.address.placeholder"}
                                changefunction={this.updateInput}
                                oneditfunction={this.onEdit}
                                oncancelfunction={this.onCancelAddressEdit}
                                value={this.state.email}
                                inputtype="email"
                                notoggle={!this.state.notificationId}
                                initialiseinedit={true}
                            />
                            <button type="submit">{formatMessage({id: "notification.address.form.submit"})}</button>
                        </fieldset>
                        <Alert feedback={this.state.feedback} onCloseFunction={this.clearFeedback} type={this.state.feedbackType}/>
                    </form>
                }
            </div>
        )
    }
}

export default withFirebase(injectIntl(NotificationAddressForm));
