import React, { Component } from "react";
import {FormattedMessage, injectIntl} from "react-intl";
import MessageEditable from "../components/MessageEditable";
import MessageDate from "../components/MessageDate";
import Caveat from "../components/Caveat";
import Message from "../components/Message";
import LogoutButton from "../components/LogoutButton";
import {withFirebase} from "../components/Firebase";
import Confirmation from "../components/Confirmation";
import Alert from "../components/Alert";
import * as ALERT_TYPES from "../constants/alertTypes";
import styles from "../../css/message.css";
import BackToTheTop from "../components/BackToTheTop";
import ImageOptionsBar from "../components/ImageOptionsBar";

var db = null;

class Messages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userid : null,
            name : "",
            messages : [],
            unsubscribe : null,
            feedback : "",
            messageToDelete : null
        }
        let user = this.props.firebase.auth.currentUser;
        if (user != null) {
            this.state.userid = user.uid;
            this.state.name = user.displayName || "";
            console.log("Set user id to " + this.state.userid);
        }
    }

    componentDidMount() {
        this.state.unsubscribe = this.retrieveMessages();
    }

    componentWillUnmount() {
        this.state.unsubscribe();
    }

    retrieveMessages = () => {
        db = db || this.props.firebase.firestore;
        let self = this;
        return db.collection("messages").orderBy("date", "desc").onSnapshot(function(querySnapshot) {
            self.setState({messages: []})
            querySnapshot.forEach(function(doc) {
                let message = {
                    messageid : doc.id,
                    userid : doc.data().uid,
                    name : doc.data().name,
                    date : doc.data().date,
                    content : doc.data().message,
                };
                console.log("Found some messages!");
                var messages = [...self.state.messages];
                messages.push(message);
                self.setState({messages : messages});
                console.log("Messages now has " + self.state.messages.length);
            });
        }, function(error) {
                self.updateFeedbackOnError(error)
        });
    };


    confirmDeleteMessage = (messageId) => {
        console.log("updating state with " + messageId);
        let  message = null;
        for(var i = 0; i < this.state.messages.length; i++) {
            if(messageId === this.state.messages[i].messageid) {
                message = this.state.messages[i];
            }
        }
        if(message) {
            this.setState({messageToDelete: message});
        }
    }

    confirmationCancel = () => {
        this.setState({messageToDelete: null});
    }

    getDateForMessageToDelete = () => {
        if(this.state.messageToDelete) {
            return this.state.messageToDelete.date.toDate();
        }
        return new Date();
    }


    deleteConfirmed = () => {
        console.log("Deleting " + this.state.messageToDelete);
        db = db || this.props.firebase.firestore;
        let self = this;
        db.collection("messages").doc(self.state.messageToDelete.messageid).delete()
            .then(function() {
                self.setState({messageToDelete : null});
            }).catch(function(error) {
                self.updateFeedbackWithError(error);
                self.setState({messageToDelete : null});
            });
    };


    updateFeedbackWithError = (error) => {
        this.setState({feedback : "error"});
    }

    clearFeedback = e => {
        e.preventDefault();
        this.setState({feedback : ""});
    }


    render() {
        const { formatMessage } = this.props.intl;
        var anonUser = !this.state.name;
        var userId = this.state.userid;
        var name = this.state.name;
        let confirmationHeading = formatMessage({id : "confirmation.heading"});
        let confirmationExplanation = formatMessage({ id : "confirmation.explain"});
        let confirmationOK = formatMessage({id : "confirmation.ok"});
        let confirmationCancel = formatMessage({id : "confirmation.cancel"});
        return (
            <div className="contentModule">
                <ImageOptionsBar situStyleName={"mainBackground with-zoom"} imageStyleName={"messagesBackground"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                <section className="subSection intro">
                    <h2><FormattedMessage id="messages.intro.heading"/></h2>
                    <p><FormattedMessage id="messages.intro.1"/></p>
                </section>
                <Caveat suffix="message"/>
                <section className="subSection newMessageSection">
                    <h3><FormattedMessage id="messages.new.message.heading"/></h3>
                    <div className="newEntryForm">
                        <p>{anonUser ? <FormattedMessage id="message.form.anon.user.instruction"/> : <FormattedMessage id="message.form.instruction"/>}</p>
                        <MessageEditable userid={userId} name={name}/>
                    </div>
                    <Alert feedback={this.state.feedback} onCloseFunction={this.clearFeedback} type={ALERT_TYPES.ERROR}/>
                </section>
                <section className="subSection messagesSection">
                    <h2><FormattedMessage id="messages.messages.heading"/></h2>
                    <ul>
                        {this.state.messages.map((message, index) => (
                                !anonUser && message.userid === userId ?
                                    <li key={index} className="message editable">
                                        <MessageDate date={message.date.toDate()}/>
                                        <MessageEditable userid={userId} name={name}
                                                         messageid={message.messageid}
                                                         confirmDeleteFunction={this.confirmDeleteMessage}/>
                                    </li>
                                    :
                                    <li key={index}><Message message={message} key={index}/></li>
                            ))
                        }
                    </ul>
                </section>
                <div className="backgroundElements dragonfly"></div>
                <BackToTheTop/>
                <Confirmation show={this.state.messageToDelete != null}
                              onCancelFunction={this.confirmationCancel}
                              okFunction={this.deleteConfirmed}
                              heading={confirmationHeading}
                              message={confirmationExplanation}
                              okText={confirmationOK} cancelText={confirmationCancel}/>
            </div>
        );
    }
}

export default withFirebase(injectIntl(Messages));