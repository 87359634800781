import React from "react";
import EditableField from "./EditableField";
import {FormattedMessage, injectIntl} from "react-intl";
import {withFirebase} from "./Firebase";
import LoadingIndicator from "./LoadingIndicator";
import Alert from "./Alert";
import * as ALERT_TYPES from "../constants/alertTypes";

var db = null;

class MessageEditable extends React.Component {

    constructor(props) {
        super(props);
        this.props = props;
        var isAnonUser = !this.props.name;
        this.state = {
            message : null,
            messageCache : null,
            messageHasChanged : false,
            feedback : "",
        }
        if(!this.props.messageid) {
            this.createNewMessageOnInit();
        }
    }

    componentDidMount() {
        if(this.props.messageid) {
            console.log("Retrieving message for: " + this.props.messageid);
            this.retrieveMessage();
        }
    }

    createNewMessageOnInit = () => {
        let message = this.createNewMessage();
        this.state.message = message;
        this.state.messageCache = message;
        console.log("Message is now " + this.state.message.messageid);
    };

    createNewMessage = () => {
        let  date = new Date();
        let message = {
            messageid : this.props.userid + date.getTime(),
            userid : this.props.userid,
            otherid : this.props.otherid,
            name : this.props.name,
            date : date,
            content : "",
        };
        return message;
    };

    retrieveMessage = () => {
        db = db || this.props.firebase.firestore;
        let self = this;
        let collection = this.props.otherid ? db.collection("messages-by-id") : db.collection("messages");
        let docRef = collection.doc(this.props.messageid);
        docRef.get().then(function(doc) {
            let message = {
                messageid : doc.id,
                userid : doc.data().uid,
                otherid : doc.data().otherid ? doc.data().otherid : "",
                name : doc.data().name,
                date : doc.data().date,
                content : doc.data().message,
            };
            self.setState({message: message, messageCache : message});
        }).catch(function(error) {
            self.updateFeedbackWithError(error)
        });
    };

    saveMessage = e => {
        e.preventDefault();
        console.log("Message in save is now " + this.state.message.messageid);
        let message = this.state.message;
        if(message.name.trim().length === 0) {
            this.updateFeedbackWithValidation("validation.message.no.name.for.message");
        } else if (message.content.trim().length === 0) {
            this.updateFeedbackWithValidation("validation.message.no.content.for.message");
        } else {
            if (!db) {
                db = this.props.firebase.firestore;
            }
            var self = this;
            console.log("Saving message: " + this.state.message.messageid + " " + this.state.message.content);
            let collection = this.props.otherid ? db.collection("messages-by-id") : db.collection("messages");
            collection.doc(this.state.message.messageid).set({
                uid: self.state.message.userid,
                otherid : self.props.otherid ? this.props.otherid : "",
                name: self.state.message.name,
                date: new Date(),
                message: self.state.message.content
            })
            .then(function () {
                if(!self.props.messageid) {
                    self.setState({message: self.createNewMessage()});
                }
            })
            .catch(function (error) {
                self.updateFeedbackWithError(error);
            });
        }
    };

    callConfirmDelete = () => {
        console.log("Calling delete with " + this.state.message.messageid);
        this.props.confirmDeleteFunction(this.state.message.messageid);
    }

    updateFeedbackWithError = (error) => {
        this.setState({feedback : "error"});
    }

    updateFeedbackWithValidation = (info) => {
        this.setState({feedback : info});
    }

    clearFeedback = e => {
        e.preventDefault();
        this.setState({feedback : ""});
    }

    updateContent = e => {
        e.preventDefault();
        let message = {
            messageid : this.state.message.messageid,
            userid : this.state.message.userid,
            name : this.state.message.name,
            date : new Date(),
            content : e.target.value,
        };
        this.setState({message : message, messageHasChanged : true});
    };

    updateName = e => {
        e.preventDefault();
        let message = {
            messageid : this.state.message.messageid,
            userid : this.state.message.userid,
            name : e.target.value,
            date : new Date(),
            content : this.state.message.content,
        };
        this.setState({message : message, messageHasChanged : true});
    };

    cancelUpdateContent = () => {
        console.log("Cancelling update content");
        let messageName = this.state.message.name;
        let nameHasChanged = messageName !== this.state.messageCache.name;
        let message= {
            messageid : this.state.messageCache.messageid,
            userid : this.state.messageCache.userid,
            name : nameHasChanged ? messageName : this.state.messageCache.name,
            date : this.state.messageCache.date,
            content : this.state.messageCache.content,
        };
        this.setState({message: message, messageHasChanged : nameHasChanged});
    };

    cancelUpdateName = () => {
        console.log("Cancelling update name");
        let messageContent = this.state.message.content;
        let contentHasChanged = messageContent !== this.state.messageCache.content;
        let message= {
            messageid : this.state.messageCache.messageid,
            userid : this.state.messageCache.userid,
            name : this.state.messageCache.name,
            date : this.state.messageCache.date,
            content : contentHasChanged ? messageContent : this.state.messageCache.content,
        };
        this.setState({message: message, messageHasChanged : contentHasChanged});
    };

    editCalled = () => {

    }

    clearMessage = e => {
        e.preventDefault();
        let message = {
            messageid : this.state.message.messageid,
            userid : this.props.userid,
            name : this.props.name,
            date : new Date(),
            content : "",
        };
        var isNewMessage = !this.props.messageid;
        this.setState({message : message, messageHasChanged : !isNewMessage});
    };

    undoEditMessage = e => {
        e.preventDefault();
        let message= {
            messageid : this.state.messageCache.messageid,
            userid : this.state.messageCache.userid,
            name : this.state.messageCache.name,
            date : this.state.messageCache.date,
            content : this.state.messageCache.content,
        };
        this.setState({message: message, messageHasChanged : true});
    };

    render() {
        const { formatMessage } = this.props.intl;
        var isNewMessage = !this.props.messageid;
        var isAnonUser = !this.props.name;
        var isMessageChanged = this.state.messageHasChanged;
        return (
            <div className="editableMessage">
                {this.state.message ?
                <form onSubmit={this.saveMessage}>
                    <EditableField
                        label={"message.form.content"}
                        noLabel={this.props.noLabels}
                        name="content"
                        placeholder={this.props.messagePlaceholder ? this.props.messagePlaceholder : "message.form.content.placeholder"}
                        changefunction={this.updateContent}
                        oncancelfunction={this.cancelUpdateContent}
                        oneditfunction={this.editCalled}
                        value={this.state.message.content}
                        inputtype="textarea"
                        notoggle={isNewMessage || isAnonUser}
                        useIcon={this.props.useIcons}
                    />
                    {this.props.isReadOnlyName ? "" :
                        <EditableField
                            label={"message.form.name"}
                            noLabel={this.props.noLabels}
                            name="name"
                            placeholder={"message.form.name.placeholder"}
                            changefunction={this.updateName}
                            oncancelfunction={this.cancelUpdateName}
                            oneditfunction={this.editCalled}
                            value={this.state.message.name}
                            inputtype="text"
                            notoggle={isAnonUser}
                            useIcon={this.props.useIcons}
                        />
                    }
                    {isMessageChanged ?
                        (isNewMessage?
                            <div className={this.props.useIcons ? "buttonContainer postButtons iconButtons" : "buttonContainer postButtons"}>
                                <button type="reset" className="clear" onClick={this.clearMessage}>{
                                    this.props.useIcons ? "" : formatMessage({id: "message.form.clear"})
                                }</button>
                                <button type="submit" className="submit">{
                                    this.props.useIcons ? "" : formatMessage({id: "message.form.submit.new"})
                                }</button>
                            </div>
                            :
                            <div className={this.props.useIcons ? "buttonContainer editButtons iconButtons" : "buttonContainer editButtons"}>
                                {this.props.useIcons ? "" : <p><FormattedMessage id="editable.message.instruction"/></p>}
                                <button type="reset" className="undo" onClick={this.undoEditMessage}>{
                                    this.props.useIcons ? "" : formatMessage({id: "message.form.undo"})
                                }</button>
                                <button type="reset" className="clear" onClick={this.clearMessage}>{
                                    this.props.useIcons ? "" : formatMessage({id: "message.form.clear"})
                                }</button>
                                <button type="submit" className="submit">{
                                    this.props.useIcons ? "️" : formatMessage({id: "message.form.submit.existing"})
                                }</button>
                            </div>)
                        :
                        (!isNewMessage ?
                            <div className={this.props.useIcons ? "buttonContainer deleteButton iconButtons" : "buttonContainer deleteButton"}>
                                <button type="button" className="delete"
                                        onClick={this.callConfirmDelete}>{
                                    this.props.useIcons ? "" :
                                        formatMessage({id: "message.form.delete"})
                                        }</button>
                            </div>
                            : ""
                        )
                    }
                    <Alert feedback={this.state.feedback} onCloseFunction={this.clearFeedback}
                        type={this.state.feedback === "error" ? ALERT_TYPES.ERROR : ALERT_TYPES.VALIDATION}/>
                </form>
                : <LoadingIndicator/>}

            </div>
        );
    }
}

export default withFirebase(injectIntl(MessageEditable));