export const SIGN_IN = '/';
export const REDIRECT = '/redirect';
export const HOME = '/home';
export const OUR_STORY = '/ourstory';
export const WEDDING = '/thewedding';
export const PHOTOS = '/photos';
export const CELEBRATIONS = '/celebrations';
export const THANK_YOU = '/thankyou';
export const MESSAGES = '/messages';
export const WILD_CARD = '*';

export const PAGE_NAME_SIGN_IN = "main.menu.sign.in";
export const PAGE_NAME_HOME = "page.title.home";
export const PAGE_NAME_OUR_STORY = "main.menu.our.story";
export const PAGE_NAME_WEDDING = "main.menu.the.wedding";
export const PAGE_NAME_PHOTOS = "main.menu.photos";
export const PAGE_NAME_CELEBRATIONS = "main.menu.celebrate";
export const PAGE_NAME_THANK_YOU = "main.menu.thanks";
export const PAGE_NAME_MESSAGES = "main.menu.messages";
export const PAGE_NAME_EMPTY = "page.title.empty";