
import React from "react";
import {withFirebase} from "./Firebase";
import {injectIntl} from "react-intl";

const apiKey = process.env.REACT_APP_GOOGLE_TRANSLATE_API_KEY;

const googleTranslate = require("google-translate")(apiKey);

var db = null;

class TranslatableMessage extends React.Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {translatedContent : [],
                    contentLanguage : "",
                    translationNeeded : false,
                    translate : false
        };
        this.updateStateFromDatabase();
    }

    updateStateFromDatabase = () => {
        let self = this;
        if(!(this.props.contentId && this.props.content)) {
            return;
        }
        db = db || this.props.firebase.firestore;
        let docRef = db.collection("translations").doc(this.props.contentId);
        docRef.get().then(function(doc) {
            let docExists = doc.exists;
            if(docExists) {
                console.log("Found language from database: " + doc.data().contentLanguage);
                if(doc.data().originalContent && doc.data().originalContent === self.props.content && doc.data().translatedContent) {
                    self.setState({translatedContent: doc.data().translatedContent, contentLanguage: doc.data().contentLanguage});
                } else {
                    if(doc.data().originalContent !== self.props.content) {
                        self.clearTranslationFromDatabase(doc.data().contentLanguage);
                        self.detectLanguageUsingGoogle();
                    } else {
                        self.setState({
                            translatedContent: [],
                            contentLanguage: doc.data().contentLanguage
                        });
                    }
                }

                self.updateTranslationNeeded(doc.data().contentLanguage);
            } else {
                console.log("Couldn't find language");
                self.detectLanguageUsingGoogle();
            }
        }).catch(function(error) {
            console.log("Database error : " + error);
        });
    };

    translateTextIfNeeded = () => {
        if(!(this.props.contentId && this.props.content) || this.state.translatedContent && this.state.translatedContent.length > 0) {
            return;
        }
        this.translateUsingGoogle();
    };

    detectLanguageUsingGoogle = () => {
        let self = this;

        console.log("Detecting language of " + this.props.content.substring(0,150).trim());
        return googleTranslate.detectLanguage(this.props.content.substring(0,150).trim(), function(err, detections) {
            if(detections) {
                self.saveDetectedLanguageToDatabase(detections.language);
                self.updateTranslationNeeded(detections.language);
            }
            if(err) {
                console.log("Error detecting language: " + err);
            }
        });
    };

    findLastIndexWithContent = (arrayToCheck) => {
        for(let i = arrayToCheck.length -1; i >= 0; i--) {
            if(arrayToCheck[i] && arrayToCheck[i].trim().length > 0) {
                return i;
            }
        }
    }

    translateUsingGoogle = () => {
        let self = this;
        let userLang = self.props.lang ? self.props.lang : "en";
        let paragraphArray = this.getArrayFromStringWithParagraphs(this.props.content);
        let translatedParagraphArray = new Array(paragraphArray.length);
        let lastIndexWithContent = this.findLastIndexWithContent(paragraphArray);
        console.log("Translating Language " + paragraphArray);
        paragraphArray.forEach((paragraph, index) => {
            if(paragraph.trim().length !== 0) {
                googleTranslate.translate(paragraphArray[index].trim(), userLang, function (err, translation) {
                    let translationArray = self.state.translatedContent;
                    if (translation) {
                        translationArray[index] = translation.translatedText;
                        self.setState({translatedContent : translationArray});
                        if(index === lastIndexWithContent) {
                            self.saveTranslationToDatabase(translationArray);
                        }
                    }
                    if (err) {
                        console.log("Error translating: " + err);
                    }
                });
            } else {
                translatedParagraphArray[index] = paragraphArray[index];
            }
        });

        this.setState({translatedContent : translatedParagraphArray});
        //let self = this;
        // googleTranslate.translate(this.getArrayFromStringWithParagraphs(this.props.content), userLang, function (err, translation) {
        //     if (translation) {
        //         let translations = Array.isArray(translation.translatedText) ? translation.translatedText : [translation.translatedText];
        //         self.setState({translatedContent: translations});
        //         self.saveTranslationToDatabase(translations)
        //     }
        //     if (err) {
        //         console.log("Error translating: " + err);
        //     }
        // });
    };

    saveDetectedLanguageToDatabase = (language) => {
        let self = this;
        db = db || this.props.firebase.firestore;
        db.collection("translations").doc(this.props.contentId).set({
            contentLanguage: language,
        })
        .catch(function (error) {
            console.log("Database error " + error);
        });
    };

    clearTranslationFromDatabase = (contentLanguage) => {
        let self = this;
        console.log("Clearing translations " + this.props.contentId);
        db.collection("translations").doc(this.props.contentId).delete();
    }

    saveTranslationToDatabase = (translations) => {
        let self = this;
        console.log("Saving translations " + this.props.contentId + " " + self.state.contentLanguage + " " + translations);
        db.collection("translations").doc(this.props.contentId).set({
            contentLanguage: self.state.contentLanguage,
            originalContent: self.props.content,
            translatedContent: translations
        })
        .catch(function (error) {
            console.log("Database error " + error);
        });
    };

    getArrayFromStringWithParagraphs = (stringToTransform) => {
        return stringToTransform.split("\n");
    };

    getStringWithParagraphsFromArray = (arrayToTransform) => {
        let result = "";
        arrayToTransform.forEach((element, index) => result = result + (index > 0 ? "\n" : "") + element);
        return result;
    };

    updateTranslationNeeded = (contentLanguage) => {
        let userLang = this.props.lang ? this.props.lang : "en";
        this.setState({contentLanguage: contentLanguage, translationNeeded : userLang !== contentLanguage});
    };

    toggleTranslation = () => {
        console.log("Toggling state");
        this.translateTextIfNeeded();
        this.setState({translate : !this.state.translate})
    };

    getTranslatableContent = () => {
        if(this.state.translate) {
            return this.getStringWithParagraphsFromArray(this.state.translatedContent);
        }
        return this.props.content;
    };

    getLinkMessage = () => {
        const { formatMessage } = this.props.intl;
        if(this.state.translate) {
            return formatMessage({id: "translatable.view.original"});
        }
        return formatMessage({id: "translatable.translate.comment"});
    };

    render() {
        return (
            this.state.translationNeeded ?
                <div className={"translatableContent"}>
                    <span className={"translatableMessageContent"}>{
                        this.getTranslatableContent()
                    }</span>
                    <a onClick={this.toggleTranslation}>{this.getLinkMessage()}</a>
                </div>
                :
                <span className={"nonTranslatableContent"}>{this.props.content}</span>
        );
    }

}

export default withFirebase(injectIntl(TranslatableMessage));