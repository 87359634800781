import React, { Component } from "react";
import {
    Route,
    Switch,
    BrowserRouter as Router,
} from "react-router-dom";
import Home from "./js/routes/Home";
import OurStory from "./js/routes/OurStory";
import SpecialThanks from "./js/routes/SpecialThanks";
import TheWedding from "./js/routes/TheWedding";
import Photos from "./js/routes/Photos";
import Celebrations from "./js/routes/Celebrations";
import Messages from "./js/routes/Messages";
import Footer from "./js/components/Footer";
import MainMenu from "./js/components/MainMenu";
import Login from "./js/routes/Login";
import RedirectAfterLogin from "./js/routes/RedirectAfterLogin";
import NotFound from "./js/routes/NotFound";

import {IntlProvider} from 'react-intl';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import messages_en from "./translations/en";
import messages_es from "./translations/es";
import Cookies from 'universal-cookie';
import * as ROUTES from './js/constants/routes';
import * as COOKIE_NAMES from './js/constants/cookies';
import {withFirebase} from "./js/components/Firebase";
import PrivateRoute from "./js/components/PrivateRoute";
import HistoryListener from "./js/components/HistoryListener";
import PhotoZoomBox from "./js/components/PhotoZoomBox";


import ScrollToTop from "./js/components/ScrollToTop";

const cookies = new Cookies();

let i18nConfig = {
    locale: 'en',
    messages: messages_en
};


const browserLanguage = navigator.language.split(/[-_]/)[0];  // language without region code
const cookieLanguage = cookies.get(COOKIE_NAMES.TRANS_COOKIE);

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isMenuToggled: false,
            authUser: null,
            authenticated: false,
            page : "",
            welcomeVideoPlayed : !navigator.cookieEnabled ? true : cookies.get(COOKIE_NAMES.LANDING_VIDEO_ALREADY_PLAYED_COOKIE) ? true : false,
            showPhotoZoom : false,
            photoToShow : "",
            zoomOrigin : "center"
        };
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.onChangeLanguage(cookieLanguage ? cookieLanguage : browserLanguage);
        this.onRouteChanged = this.onRouteChanged.bind(this);
    }

    onChangeLanguage(lang) {
        console.log("changing language to: " + lang);
        switch (lang) {
            case 'es': i18nConfig.messages = messages_es; break;
            case 'en': i18nConfig.messages = messages_en; break;
            default: i18nConfig.messages = messages_en; break;
        }
        this.setState({locale : lang});
        i18nConfig.locale = lang;
    }

    doChangeLanguage = (lang) => {
        this.onChangeLanguage(lang);
    }

    componentDidMount() {
        this.listener = this.props.firebase.auth.onAuthStateChanged(
            authUser => {
                authUser
                    ? this.setState({ authUser })
                    : this.setState({ authUser: null });
                this.setState({authenticated : true});
            },
        );
    }



    onWelcomeVideoPlayed = () => {
        this.setState({welcomeVideoPlayed: true});
    }



    onRouteChanged(location) {
        if(location !== ROUTES.SIGN_IN && location !==ROUTES.REDIRECT) {
            switch(location) {
                case ROUTES.HOME: case ROUTES.OUR_STORY: case ROUTES.WEDDING: case ROUTES.PHOTOS: case ROUTES.CELEBRATIONS: case ROUTES.MESSAGES: case ROUTES.THANK_YOU:
                cookies.set(COOKIE_NAMES.PATH_COOKIE, location, { path: '/' , expires: new Date(Date.now()+30000000000) });
                break;
            }
        }
        console.log("Page is " + location);
        this.setState({page: location.substr(1)});
    }

    componentWillUnmount() {
        this.listener();
    }



    zoomPhoto = (styleName, zoomOrigin) => {
        console.log("Showing photo " + styleName);
        this.setState({zoomOrigin : zoomOrigin, showPhotoZoom: true, photoToShow : styleName});
    };

    hidePhotoZoom = (styleName) => {
        console.log("Hiding photo " + styleName);
        this.setState({showPhotoZoom: false});
    };



    render() {


        return (
            <IntlProvider key={ i18nConfig.locale } locale={ i18nConfig.locale }  messages={ i18nConfig.messages }>
                <Router>
                    <ScrollToTop>
                        <div className={"contentWrapper " + this.state.page + " welcome-video-played-" + this.state.welcomeVideoPlayed}>
                            <HistoryListener currentPageChanged={this.onRouteChanged}/>
                            <MainMenu locale={i18nConfig.locale} authUser={this.state.authUser}
                                      changeLanguageFunction={this.doChangeLanguage}
                                      onChangeLanguage={this.onChangeLanguage}/>
                            <PhotoZoomBox showPhotoZoom={this.state.showPhotoZoom} photoStyleName={this.state.photoToShow} hidePhotoZoomFunction={this.hidePhotoZoom}
                                          zoomOrigin={this.state.zoomOrigin}/>
                            <section className="content">
                                <TransitionGroup>
                                    <CSSTransition
                                        in={true}
                                        timeout={100}
                                        key={this.state.page}
                                        classNames='fade'
                                    >
                                        <Switch>
                                            <Route exact path={ROUTES.SIGN_IN} render={
                                                (props) => <Login authUser={this.state.authUser} locale={i18nConfig.locale} loaded={this.state.authenticated}/>
                                            }/>
                                            <Route path={ROUTES.REDIRECT} render={
                                                (props) => <RedirectAfterLogin/>
                                            }/>
                                            <Route path={ROUTES.HOME} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <Home locale={ i18nConfig.locale }
                                                              zoomPhoto={this.zoomPhoto}
                                                              welcomeVideoPlayedFunction={this.onWelcomeVideoPlayed}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.OUR_STORY} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <OurStory
                                                            locale={ i18nConfig.locale }
                                                            zoomPhoto={this.zoomPhoto}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.WEDDING} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <TheWedding zoomPhoto={this.zoomPhoto}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.PHOTOS} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <Photos locale={ i18nConfig.locale }  zoomPhoto={this.zoomPhoto}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.CELEBRATIONS} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <Celebrations locale={ i18nConfig.locale } zoomPhoto={this.zoomPhoto}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.THANK_YOU} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <SpecialThanks zoomPhoto={this.zoomPhoto}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.MESSAGES} render={
                                                (props) =>
                                                    <PrivateRoute authUser={this.state.authUser}>
                                                        <Messages locale={ i18nConfig.locale } zoomPhoto={this.zoomPhoto}/>
                                                    </PrivateRoute>
                                            }/>
                                            <Route path={ROUTES.WILD_CARD} component={NotFound}/>
                                        </Switch>
                                    </CSSTransition>
                                </TransitionGroup>
                                </section>
                            <Footer authUser={this.state.authUser}/>
                        </div>
                    </ScrollToTop>
                </Router>
            </IntlProvider>
        );
    }
}

export default withFirebase(Main);