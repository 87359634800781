import React, { Component } from "react";
import NotificationAddressForm from "../components/NotificationAddressForm";
import {FormattedMessage, injectIntl} from "react-intl";
import Caveat from "../components/Caveat";
import styles from "../../css/celebrate.css";
import BackToTheTop from "../components/BackToTheTop";
import ImageOptionsBar from "../components/ImageOptionsBar";

class Celebrations extends Component {

    constructor(props) {
        super(props)
        this.props = props;
    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className="contentModule">
                <ImageOptionsBar situStyleName={"mainBackground with-zoom"} imageStyleName={"celebrateBackground"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                <section className="subSection intro">
                    <h2><FormattedMessage id="celebration.intro.heading"/></h2>
                    <p><FormattedMessage id="celebration.intro.1"/></p>
                    <p><FormattedMessage id="celebration.intro.2"/></p>
                </section>
                <Caveat suffix="notification"/>
                <section  className="subSection celebration uk">
                    <h2><FormattedMessage id="celebration.title.uk"/></h2>
                    <div className="details">
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.when"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.when.uk"/></span>
                        </p>
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.where"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.where.uk"/></span>
                        </p>
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.why"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.why.uk"/></span>
                        </p>
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.what"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.what.uk"/></span>
                        </p>
                    </div>
                    <NotificationAddressForm collection="london-addresses" updatedescription="celebration.update.description.uk" locale={ this.props.locale }/>
                </section>
                <section className="subSection celebration es">
                    <h2><FormattedMessage id="celebration.title.es"/></h2>
                    <div className="details">
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.when"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.when.es"/></span>
                        </p>
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.where"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.where.es"/></span>
                        </p>
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.why"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.why.es"/></span>
                        </p>
                        <p className="detail">
                            <label><FormattedMessage id="celebration.label.what"/></label>
                            <span className="detail-description"><FormattedMessage id="celebration.what.es"/></span>
                        </p>
                    </div>
                    <NotificationAddressForm collection="galicia-addresses" updatedescription="celebration.update.description.es" locale={ this.props.locale }/>
                </section>
                <div className="backgroundElements stars"></div>
                <BackToTheTop/>
            </div>
        );

    }
}

export default injectIntl(Celebrations);