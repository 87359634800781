import app from 'firebase/app';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyD9dwpvn0lJ-9Z7oiNvZbEYl7-a3Bt18eI",
    authDomain: "we-did.firebaseapp.com",
    databaseURL: "https://we-did.firebaseio.com",
    projectId: "we-did",
    storageBucket: "we-did.appspot.com",
    messagingSenderId: "340525934104"
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        this.auth = app.auth();
        this.firestore = app.firestore();
    }

    doSignOut = () => this.auth.signOut();

    doLoginAnoynmously = () => this.auth.signInAnonymously();

}

export default Firebase;