import React, { Component } from "react";
import DateDiff from "date-diff";
import styles from "../../css/countdownclock.css";
import {FormattedMessage} from 'react-intl';

class CountDownClock extends Component {
    render() {
        let diff = new DateDiff(new Date("May 20, 2019"), new Date());
        let daysCount = Math.ceil(diff.days());


        let todayAtTime = new Date();
        todayAtTime.setHours(18);
        let hoursDiff = new DateDiff(todayAtTime, new Date());
        let hoursCount = Math.ceil(hoursDiff.hours());

        if(hoursCount > 0 && daysCount < 0) {
            hoursCount = 24 - hoursCount;
            daysCount = daysCount + 1;
        } else if (hoursCount < 0 && daysCount > 0) {
            hoursCount = 24 + hoursCount;
            daysCount = daysCount - 1;
        }


        let past = (daysCount < 0 || (daysCount === 0 && hoursCount < 1));

        let posDaysCount = daysCount > -1 ? daysCount : (-1 * daysCount);

        let daysPlural = (posDaysCount === 0 || posDaysCount > 1);

        let posHoursCount = hoursCount > -1 ? hoursCount : (-1 * hoursCount);

        let hoursPlural = (posHoursCount === 0 || posHoursCount > 1);


        return (
            <span className="countDownClock">
                <span className="ccDays">
                    {posDaysCount}
                </span>
                <FormattedMessage id={daysPlural ? "countdown.days" : "countdown.day"} description="Plural day descriptor."/>
                <FormattedMessage id="countdown.and" description="And joiner."/>
                <span className="ccDays">
                    <FormattedMessage id={hoursPlural && past ? "countdown.hours.past" :
                                            hoursPlural && !past ? "countdown.hours.future" :
                                            !hoursPlural && past ? "countdown.hour.past" :
                                                "countdown.hour.future"}
                                      description="Plural day descriptor."
                                      values={{ hoursCount: posHoursCount }}/>
                    !
                </span>
            </span>
        );
    }
}

export default CountDownClock;