import React from "react";
import {FormattedDate, FormattedMessage, FormattedTime, injectIntl} from "react-intl";

function MessageDate(props) {
    const { formatMessage } = props.intl;

    let months = [formatMessage({id: "month.1"}), formatMessage({id: "month.2"}), formatMessage({id: "month.3"}), formatMessage({id: "month.4"}),
        formatMessage({id: "month.5"}), formatMessage({id: "month.6"}), formatMessage({id: "month.7"}), formatMessage({id: "month.8"}),
        formatMessage({id: "month.9"}), formatMessage({id: "month.10"}), formatMessage({id: "month.11"}), formatMessage({id: "month.12"})];

    let date = props.date.getDate();
    let month = months[props.date.getMonth()];
    let year = props.date.getFullYear();

    return (
        <div className="messageDate">
            <label><FormattedMessage id="message.form.date"/></label>
            <div className="dateAndTime">
                <span className="date">{date} {month} {year}</span>
                <FormattedTime className="time" value={props.date}/>
            </div>
        </div>
    );
}

export default injectIntl(MessageDate);