import React from "react";
import styles from "../../css/iframe-box.css";

class IFrameBox extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className={this.props.show ? "iframe-container-visible modal-visible" : "iframe-container-hidden modal-hidden"}>
                <div className="modal-background"/>
                <div className="iframeContainer">
                    <div className="closeButton" onClick={this.props.closeModal}/>
                    <iframe src={"https://www.google.com/webhp?igu=1"}/>
                </div>
            </div>
        )
    }
}

export default IFrameBox;