import React, { Component } from "react";
import {FormattedMessage} from "react-intl";
import styles from "../../css/big-day.css";
import { Parallax, Background } from "react-parallax";
import BackToTheTop from "../components/BackToTheTop";
import ImageOptionsBar from "../components/ImageOptionsBar";

const insideStyles = {
    padding: 20,
    position: "absolute",
    top: "50%",
    left: "50%",
    font: "Special Elite",
    transform: "translate(-50%,-50%)"
};

class TheWedding extends Component {
    render() {
        return (
            <div className="contentModule wedding">
                <ImageOptionsBar situStyleName={"mainBackground with-zoom"} imageStyleName={"bigDayBackground"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                <div className="wrapper">

                    <section className="section static subSection wedding-intro">
                            <h2><FormattedMessage id="wedding.day.heading"/></h2>
                            <p className="bodyText"><FormattedMessage id="wedding.day.intro.1"/></p>
                            <p className="bodyText"><FormattedMessage id="wedding.day.intro.2"/></p>
                            <p className="bodyText"><FormattedMessage id="wedding.day.intro.3"/></p>
                        <Parallax strength={-800}>
                            <div style={{ height: 400}}>
                                <h2 style={insideStyles}><FormattedMessage id="wedding.day.intro.para"/></h2>
                            </div>
                            <Background className="custom-bg flight">
                            </Background>
                        </Parallax>
                    </section>

                    <section className="subSection route">
                            <h2><FormattedMessage id="wedding.day.details.heading"/></h2>
                            <p className="bodyText"><FormattedMessage id="wedding.day.details.intro"/></p>
                            <p className="bodyText"><FormattedMessage id="wedding.day.details.1"/></p>
                            <Parallax strength={750}>
                                <div style={{ height: 450}}>
                                    <h2 style={insideStyles}><FormattedMessage id="wedding.day.para.route"/></h2>
                                </div>
                                <Background className="custom-bg driving-route">
                                </Background>
                            </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.2"/></p>
                        <Parallax strength={300}>
                            <div style={{ height: 400}}>
                                <h2 style={insideStyles}><FormattedMessage id="wedding.day.para.townsend"/></h2>
                            </div>
                            <Background className="custom-bg gracehill">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.3"/></p>
                        <Parallax strength={900}>
                            <div style={{ height: 300}}>
                                <h2 style={insideStyles}><FormattedMessage id="wedding.day.para.dolly"/></h2>
                            </div>
                            <Background className="custom-bg dolly">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.4"/></p>
                        <Parallax strength={900}>
                            <div style={{ height: 300}}>
                                <h2 style={insideStyles}><FormattedMessage id="wedding.day.para.cookies"/></h2>
                            </div>
                            <Background className="custom-bg cookies">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.5"/></p>
                        <Parallax strength={-200}>
                            <div style={{ height: 500}} className="river">
                                <h2 style={insideStyles}><FormattedMessage id="wedding.day.para.river"/></h2>
                            </div>
                            <Background className="custom-bg river">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.6"/></p>
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.7"/></p>
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.8"/></p>
                        <Parallax strength={400}>
                            <div style={{ height: 800}} className={"church"}>
                                <h2 style={insideStyles}><FormattedMessage id="wedding.day.para.church"/></h2>
                            </div>
                            <Background className="custom-bg church">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.9"/></p>
                        <Parallax strength={150}>
                            <div style={{ height: 400}}>
                            </div>
                            <Background className="custom-bg cove">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <Parallax strength={150}>
                            <div style={{ height: 400}}>
                            </div>
                            <Background className="custom-bg park">
                            </Background>
                        </Parallax>
                    </section>
                    <section className="subSection">
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.10"/></p>
                        <Parallax strength={150}>
                            <div style={{ height: 400}}>
                            </div>
                            <Background className="custom-bg on-the-road">
                            </Background>
                        </Parallax>
                        <p className="bodyText"><FormattedMessage id="wedding.day.details.11"/></p>
                        <div className="backgroundElements couple"/>
                    </section>
                    <section className="subSection">
                        <h2><FormattedMessage id="wedding.day.since.heading"/></h2>
                        <p className="bodyText"><FormattedMessage id="wedding.day.since.details.1"/></p>
                        <p className="bodyText"><FormattedMessage id="wedding.day.since.details.2"/></p>
                        <Parallax strength={-150}>
                            <div style={{ height: 500}}>
                            </div>
                            <Background className="custom-bg roadtrip">
                            </Background>
                        </Parallax>
                        <p className="bodyText"><FormattedMessage id="wedding.day.since.details.3"/></p>
                        <div className="backgroundElements trees"/>
                    </section>
                    <BackToTheTop/>
                </div>
            </div>
        );
    }
}

export default TheWedding;