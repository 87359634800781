import React from "react";
import {injectIntl} from "react-intl";

class EditableField extends React.Component {

    constructor(props) {
        super(props);
        let hasValue = (props.value !== null) && (props.value != "") ;
        this.state = {
            editMode: !hasValue,
        };
        this.toggleEditMode = this.toggleEditMode.bind();
    }

    toggleEditMode = () => {
        if(this.props.notoggle) {
           return;
        }
        if(this.state.editMode) {
            console.log("Calling cancel function");
            this.props.oncancelfunction();
        } else {
            this.props.oneditfunction();
        }
        this.setState({
            editMode: !this.state.editMode
        });


    }

    render() {
        const { formatMessage } = this.props.intl;
        return (
            <div className={"editableField " + this.props.name}>
                {this.props.noLabel ? "" : <label className="description">{formatMessage({id: this.props.label})}</label>}
                <div className={this.props.notoggle ? "value" : "value withToggle"}>
                    {this.state.editMode ?
                        (this.props.inputtype == "textarea" ?
                        <textarea name={this.props.name}
                               placeholder={formatMessage({id: this.props.placeholder})}
                               onChange={this.props.changefunction}
                               value={this.props.value}
                        />
                        :
                        <input name={this.props.name}
                               placeholder={formatMessage({id: this.props.placeholder})}
                               onChange={this.props.changefunction}
                               value={this.props.value}
                               type={this.props.inputtype}
                        />) :
                        <span className="staticValue">{this.props.value}</span>
                    }
                    {this.props.notoggle
                        ? ""
                        :
                        this.props.useIcon ?
                            <div className={"buttonContainer iconButtons"}>
                                <button type="reset" className={this.state.editMode ? "view" : "edit"} onClick={this.toggleEditMode}/>
                            </div>
                            :
                        <a className={this.state.editMode ? "toggle edit" : "toggle view"} onClick={this.toggleEditMode}>
                            {formatMessage({id: this.state.editMode ? "editable.field.toggle.link.cancel" : "editable.field.toggle.link.edit"})}
                        </a>
                    }
                </div>
            </div>
        )
    }
}

export default injectIntl(EditableField);