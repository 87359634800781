import React, { Component } from "react";
import {FormattedMessage} from "react-intl";
import Gallery from "../components/Gallery";
import styles from "../../css/thanks.css";
import BackToTheTop from "../components/BackToTheTop";
import ImageOptionsBar from "../components/ImageOptionsBar";

class SpecialThanks extends Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        return (
            <div className="contentModule">
                <ImageOptionsBar situStyleName={"mainBackground with-zoom"} imageStyleName={"thanksBackground"} zoomPhoto={this.props.zoomPhoto} zoomOrigin="center"/>
                <section className="subSection">
                    <h2><FormattedMessage id="thanks.intro.heading"/></h2>
                    <p><FormattedMessage id="thanks.intro.1"/></p>
                    <p><FormattedMessage id="thanks.intro.2"/></p>
                    <p><FormattedMessage id="thanks.intro.3"/></p>
                </section>
                <section className="subSection with-gallery">
                    <h2><FormattedMessage id="thanks.rings.title"/></h2>
                    <Gallery zoomPhoto={this.props.zoomPhoto}
                             galleryItems={["sand-1","sand-2","sand-3", "sand-4","sand-5","sand-6","rings-1","rings-2","rings-3","rings-4","rings-5"]}
                             captionItems={[
                                 <div>
                                     <p><FormattedMessage id="thanks.rings.details.1"/></p>
                                     <p><FormattedMessage id="thanks.rings.details.1b"/></p>
                                     <p><FormattedMessage id="thanks.rings.details.1c"/></p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.rings.europe"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.2"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.2b"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.rings.n.america"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.3a"/>
                                         <a href="https://addisononamelia.com/" target="_blank">The Addison On Amelia Island</a>
                                         <FormattedMessage id="thanks.rings.details.3b"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.rings.australia"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.4"/>
                                     </p>
                                     <label><FormattedMessage id="thanks.rings.africa"/></label>
                                     <p>

                                         <FormattedMessage id="thanks.rings.details.5"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.rings.s.america"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.7a"/>
                                         <a href="https://www.etsy.com/uk/shop/saharagems" target="_blank">Sahara Gems</a>
                                         <FormattedMessage id="thanks.rings.details.7b"/>
                                     </p>

                                     <label><FormattedMessage id="thanks.rings.asia"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.6"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.rings.antarctica"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.8a"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.8b"/>
                                         <a href="http://www.ukaht.org/" target="_blank">UK Antarctic Heritage Trust</a>
                                         <FormattedMessage id="thanks.rings.details.8c"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.8d"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.rings.surprise"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.9"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.9b"/>
                                     </p>
                                 </div>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.rings.details.10a"/>
                                     <a href="https://www.etsy.com/uk/shop/Cardtorial" target="_blank">Cardtorial</a>
                                     <FormattedMessage id="thanks.rings.details.10b"/>
                                 </p>
                                 ,
                                 <div>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.11a"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.11b"/>
                                         <a href="https://www.etsy.com/uk/shop/Woodthingstore" target="_blank">WoodThingStore</a>
                                         <FormattedMessage id="thanks.rings.details.11c"/>
                                     </p>
                                 </div>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.rings.details.13a"/>
                                     <a href="https://www.instagram.com/defunkyard/?hl=en" target="_blank">DeFunkYard</a>
                                     <FormattedMessage id="thanks.rings.details.13b"/>
                                 </p>
                                 ,
                                 <div>
                                     <p>
                                         <FormattedMessage id="thanks.rings.details.12a"/>
                                         <a href="https://www.justind.co.uk/" target="_blank">Justin Duance</a>
                                         <FormattedMessage id="thanks.rings.details.12b"/>
                                     </p>
                                 </div>
                             ]}/>

                </section>
                <section className="subSection with-gallery">
                    <h2><FormattedMessage id="thanks.announcement.title"/></h2>
                    <Gallery zoomPhoto={this.props.zoomPhoto}
                             galleryItems={["announcement-2","announcement-1","announcement-5", "announcement-3","announcement-4"]}
                             captionItems={[
                                 <p>
                                     <FormattedMessage id="thanks.announcement.thanks.1"/>
                                 </p>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.announcement.thanks.1b"/>
                                 </p>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.announcement.thanks.2a"/>
                                     <a href="https://www.etsy.com/uk/shop/HangaHarbor" target="_blank">Hanga Harbour</a>
                                     <FormattedMessage id="thanks.announcement.thanks.2b"/>
                                 </p>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.announcement.thanks.3a"/>
                                     <a href="https://khadi.com/" target="_blank">Khadi Paper</a>
                                     <FormattedMessage id="thanks.announcement.thanks.3b"/>
                                 </p>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.announcement.thanks.3c"/>
                                 </p>

                             ]}/>
                </section>
                <section className="subSection with-gallery">
                    <h2><FormattedMessage id="thanks.website.title"/></h2>
                    <Gallery zoomPhoto={this.props.zoomPhoto}
                            galleryItems={["website-3","website-1","website-2"]}
                            captionItems={[
                                <p>
                                    <FormattedMessage id="thanks.website.thanks.2"/>
                                </p>
                                ,
                                <p>
                                    <FormattedMessage id="thanks.website.thanks.1"/>
                                </p>
                                ,
                                <p>
                                    <FormattedMessage id="thanks.website.thanks.1b"/>
                                </p>

                            ]}/>
                </section>
                <section className="subSection with-gallery before-us-thanks">
                    <h2><FormattedMessage id="thanks.ceremony.heading"/></h2>
                    <Gallery zoomPhoto={this.props.zoomPhoto}
                             galleryItems={["question","permit","flowers","ceremony","photography","cookies"]}
                             captionItems={[
                                 <div>
                                     <label><FormattedMessage id="thanks.dress.title"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.dress.intro"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.dress.thanks.1a"/>
                                         <a href="https://www.momso.co.uk/" target="_blank">Momso</a>
                                         <FormattedMessage id="thanks.dress.thanks.1b"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.dress.thanks.2a"/>
                                         <a href="https://www.etsy.com/uk/shop/AnnNottinghamBridal" target="_blank">Ann Nottingham Bridal</a>
                                         <FormattedMessage id="thanks.dress.thanks.2b"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.dress.thanks.3a"/>
                                         <a href="https://www.etsy.com/uk/shop/KUKLAfashiondesign" target="_blank">Kukla Shoes</a>
                                         <FormattedMessage id="thanks.dress.thanks.3b"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.park.title"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.park.thanks.1"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.flowers.title"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.flowers.thanks.1a"/>
                                         <a href="http://sevierblumen.com/" target="_blank">Sevier Blumen</a>
                                         <FormattedMessage id="thanks.flowers.thanks.1b"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.ceremony.title"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.ceremony.thanks.1a"/>
                                         <a href="https://agrandbeginning.com/" target="_blank">A Grand Beginning</a>
                                         <FormattedMessage id="thanks.ceremony.thanks.1b"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <p>
                                         <FormattedMessage id="thanks.ceremony.thanks.2a"/>
                                         <a href="https://www.instagram.com/juicebeats1/" target="_blank">Juicebeats Photography</a>
                                         <FormattedMessage id="thanks.ceremony.thanks.2b"/>
                                     </p>
                                 </div>
                                 ,
                                 <div>
                                     <label><FormattedMessage id="thanks.cake.title"/></label>
                                     <p>
                                         <FormattedMessage id="thanks.cake.thanks.1a"/>
                                         <a href="http://thewildflourbakehouse.com/" target="_blank">The Wildflour Bakehouse</a>
                                         <FormattedMessage id="thanks.cake.thanks.1b"/>
                                     </p>
                                 </div>

                             ]}/>
                </section>
                <div className="backgroundElements us-thanks"></div>

                <section className="subSection with-gallery no-counter">
                    <h2><FormattedMessage id="thanks.support.title"/></h2>
                    <Gallery zoomPhoto={this.props.zoomPhoto}
                             galleryItems={["family-1","family-3","family-4","family-2",]}
                             captionItems={[

                                 <p>
                                     <FormattedMessage id="thanks.support.thanks.1"/>
                                 </p>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.support.thanks.2"/>
                                 </p>,
                                 <p>
                                     <FormattedMessage id="thanks.support.thanks.3"/>
                                 </p>
                                 ,
                                 <p>
                                     <FormattedMessage id="thanks.support.thanks.4"/>
                                 </p>,
                                 <div>
                                     <p>
                                         <FormattedMessage id="thanks.support.thanks.5a"/>
                                     </p>
                                     <p>
                                        <FormattedMessage id="thanks.support.thanks.5b"/>
                                     </p>
                                 </div>,
                             ]}
                    />

                </section>
                <section className="subSection with-gallery no-counter">
                    <h2><FormattedMessage id="thanks.memories.title"/></h2>
                    <Gallery zoomPhoto={this.props.zoomPhoto}
                             galleryItems={["martin", "nic"]}
                             captionItems={[
                                 <div>
                                     <p>
                                         <FormattedMessage id="thanks.memories.thanks.2a"/>
                                     </p>
                                     <p>
                                         <FormattedMessage id="thanks.memories.thanks.2b"/>
                                     </p>
                                 </div>,
                                 <p>
                                     <FormattedMessage id="thanks.memories.thanks.1"/>
                                 </p>

                             ]}
                             fadeOutAnimation={true}
                    />
                </section>
                <div className="backgroundElements flowers"></div>
                <BackToTheTop/>
            </div>
        );
    }
}

export default SpecialThanks;