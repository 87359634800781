import React from "react";
import {withFirebase} from "./Firebase";
import {injectIntl} from "react-intl";

var db = null;

class LikeButton extends React.Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {likeExists : false};
    }

    componentDidMount() {
        if(this.props.likeId) {
            db = db || this.props.firebase.firestore;
            this.likesRef = db.collection("likes");
            this.retrieveLikeState();
        }
    }

    componentWillUnmount() {
    }

    retrieveLikeState = () => {
      var user = this.props.firebase.auth.currentUser;
        let self = this;
        if(this.likesRef) {
            var docRef = this.likesRef.doc(self.props.likeId + "-" + user.uid);
            docRef.get().then(function(doc) {
                let docExists = doc.exists;
                if(docExists) {
                    self.setState({likeExists: docExists});
                }
            });
        }
    };


    updateLikeState = () => {
        var self = this;

        var user = this.props.firebase.auth.currentUser;
        if(!user.displayName) {
            this.props.showAnonUserAlertFunction();
        } else {
            console.log("Like button clicked for " + self.props.likeId + " and user " + user.uid + " and like exists " + this.state.likeExists);
            if (this.state.likeExists) {
                var docRef = this.likesRef.doc(self.props.likeId + "-" + user.uid);
                docRef.delete().then(function () {
                    console.log("Deleted like for " + self.props.likeId + " and user " + user.uid);
                });
            } else {
                this.likesRef.doc((self.props.likeId + "-" + user.uid)).set({
                    likeId: self.props.likeId,
                    userId: user.uid,
                    name: user.displayName
                })
                    .then(function () {
                        console.log("Added like for " + self.props.likeId + " and user " + user.uid);
                    });
            }
            self.setState({likeExists: !this.state.likeExists});
        }
    };

    render() {
        return (
            <div className={this.state.likeExists ? "likeButtonContainer liked" : "likeButtonContainer notLiked"}>
                <div className="unLikeButton" onClick={this.updateLikeState}></div>
                <div className="likeButton" onClick={this.updateLikeState}></div>
            </div>
        );
    }

}

export default withFirebase(LikeButton);