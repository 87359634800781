import React from "react";
import {withFirebase} from "./Firebase";
import {injectIntl} from "react-intl";
import MessageDate from "../routes/Messages";

var db = null;

class LikeList extends React.Component {

    constructor(props) {
        super();
        this.props = props;
        this.state = {
            names : [],
            unsubscribe : null
        }
    }

    componentDidMount() {
        console.log("Mounting list");
        this.state.unsubscribe = this.retrieveLikeList();
    }

    componentWillUnmount() {
        console.log("Unmounting list");
        if(this.state.unsubscribe) {
            this.state.unsubscribe();
        }
    }

    componentDidMount() {
        if(this.props.likeId) {
            db = db || this.props.firebase.firestore;
            this.likesRef = db.collection("likes");
            this.retrieveLikeList();
        }
    }


    retrieveLikeList = () => {
        let self = this;
        if(this.likesRef) {
            return db.collection("likes").where("likeId","==",self.props.likeId).onSnapshot(function(querySnapshot) {
                self.setState({names: []})
                querySnapshot.forEach(function(doc) {
                    var names = [...self.state.names];
                    names.push(doc.data().name);
                    self.setState({names : names});
                    console.log("Found names from db: " + names);
                });
            });
        }
    };

    getFullNameList = (limit) => {
        if(this.state.names.length < limit) {
            return "";
        }
        let i;
        let list = "";
        for (i = 0; i < this.state.names.length; i++) {
            list += this.state.names[i];
            if(i < this.state.names.length - 2) {
                list += ", ";
            } else if(i < this.state.names.length - 1) {
                list += " and ";
            }
        }
        return "Liked by " + list;
    }

    render() {
        console.log("Names list: " + this.state.names);
        const { formatMessage } = this.props.intl;
        return (
            <div className={"likeList"}>
                <div className="list longList"  title={this.getFullNameList(4)}>
                    {this.state.names.length === 0 ? "" :
                        <span className="prefix">{formatMessage({id: "like.list.liked.by"})}</span>
                    }
                    {this.state.names.map((name, index) => (
                            <span key={index}>
                                <span className={"prefix"}>{index === 1 && this.state.names.length > 2 ? ", " :
                                    index === 2 || (index === 1 && this.state.names.length === 2) ? formatMessage({id: "like.list.and"}) : ""}</span>
                                {index === 0 || index === 1 || (index === 2 && this.state.names.length === 3) ? name :
                                    index === 2 ?
                                        ((this.state.names.length - 2) + "" + (this.state.names.length === 3 ?
                                            formatMessage({id: "like.list.other"}) : formatMessage({id: "like.list.others"}))) : ""}
                            </span>
                        ))
                    }
                </div>
                <div className="list shortList"  title={this.getFullNameList(2)}>
                    {this.state.names.length === 0 ? "" :
                        <span className="prefix">{formatMessage({id: "like.list.liked.by"})}</span>
                    }
                    {this.state.names.map((name, index) => (
                        <span key={index}>
                            <span className={"prefix"}>{index === 0 ? "" :
                                (index === 1 && this.state.names.length === 2) || index === 2 ? formatMessage({id: "like.list.and"}) : ""}</span>
                            {index === 0 ? name : index === 1 && this.state.names.length === 2 ?
                                this.state.names.length - 1 + formatMessage({id: "like.list.other"})
                                : index === 2? this.state.names.length - 1 + formatMessage({id: "like.list.others"}) : ""}
                        </span>
                    ))
                    }
                </div>
            </div>
        );
    }

}

export default withFirebase(injectIntl(LikeList));