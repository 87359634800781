import React, { Component } from "react";
import {injectIntl, addLocaleData} from 'react-intl';
import locale_en from 'react-intl/locale-data/en';
import locale_es from 'react-intl/locale-data/es';
import Cookies from 'universal-cookie';
import * as COOKIE_NAMES from '../constants/cookies';
import styles from "../../css/language-toggle.css";

const cookies = new Cookies();

addLocaleData([...locale_en, ...locale_es]);

const browserLanguage = navigator.language.split(/[-_]/)[0];  // language without region code
const cookieLanguage = null;


class LanguageToggle extends Component {

    constructor(props) {
        super(props);
        this.state = {showDropdown : false};
    }

    setCookie = (newLang) => {
        this.props.onChangeLanguage(newLang);
        cookies.set(COOKIE_NAMES.TRANS_COOKIE, newLang, { path: '/', expires: new Date(Date.now()+30000000000) });
        console.log("Cookie is now " + cookies.get(COOKIE_NAMES.TRANS_COOKIE));
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setLanguageToEnglish = () => {
        this.setCookie("en");
        this.props.changeLanguageFunction("en");
    }

    setLanguageToGalician = () => {
        this.setCookie("es");
        this.props.changeLanguageFunction("es");
    }

    showDropdown = () => {
        this.setState({showDropdown : true});
    }

    /**
     * Set the wrapper ref
     */
    setWrapperRef = (node) => {
        this.wrapperRef = node;
    }

    /**
     * Alert if clicked on outside of element
     */
    handleClickOutside = (event) => {
        if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
            this.setState({showDropdown : false});
        }
    }

    render() {
        const {formatMessage} = this.props.intl;
        var enOption = formatMessage({id: "language.toggle.en"});
        var esOption = formatMessage({id: "language.toggle.es"});
        return (
            <div ref={this.setWrapperRef} className={"languageToggle " + this.props.locale}>
                <div onClick={this.showDropdown} className={"currentSelection"}>
                    <span className="description">{this.props.locale === "en" ? enOption : esOption}</span>
                </div>
                <div className={this.state.showDropdown ? "language-options show" : "language-options hide"}>
                    <div className={this.props.locale === "en" ? "option en selected" : "option en"}
                         onClick={this.setLanguageToEnglish}>
                        {enOption}
                    </div>
                    <div className={this.props.locale === "en" ? "option es" : "option es selected"}
                         onClick={this.setLanguageToGalician}>
                        {esOption}
                    </div>
                </div>
            </div>
        );
    }
}

export default injectIntl(LanguageToggle);