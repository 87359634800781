
export const fullscreenEnabled = document.fullscreenEnabled || document.webkitFullscreenEnabled || document.mozFullScreenEnabled || document.msFullscreenEnabled;

export const requestFullscreen = (element) => {
    if (element.requestFullscreen) {
        return element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
        return element.mozRequestFullScreen();
    } else if (element.webkitRequestFullScreen) {
        return element.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
    } else if (element.msRequestFullscreen) { /* IE/Edge */
        return element.msRequestFullscreen();
    }
};

export const isInFullScreen = () => {
    return document.fullscreenElement || document.mozFullScreenElement || document.webkitFullscreenElement;
};

export const exitFullscreen = () => {
    if(isInFullScreen()) {
        if (document.exitFullscreen) {
            return document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            return document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            return document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            return document.msExitFullscreen();
        }
    }
};

export const addListener = (check) => {
    ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange"].forEach(
        eventType => document.addEventListener(eventType, check, false)
    );
};